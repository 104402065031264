import axios from "axios";
import APISettings from "./APISettings";

export default axios.create({
    baseURL: APISettings.API_URL
});

export const axiosPrivate = axios.create({
    baseURL: APISettings.API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});