import { Link } from "react-router-dom";
import "./MissingStyles.css";

const Missing = () => {
    return (
        <div className="missing__container">        
            <img src={require("../resources/images/page_missing.png")} alt="404" title="404" width="500" height="500" className="missing__image"></img>
            <article className="missing__text-container">
                <h1 style={{paddingBottom: "1rem"}}>Oops!</h1>
                <p>That page doesn't seem to exist.</p>
                <div className="flexGrow">
                    <Link 
                        to="/"
                        style={{textDecoration: "none", color: "var(--SELECTED-TEXT-COLOR)", paddingTop: "0.5rem"}}
                    >Visit Our Homepage</Link>
                </div>
            </article>
        </div>
    )
}

export default Missing
