import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import APIAuth from "../api/APIAuth";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';
import APISettings from "../api/APISettings";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';

const PasswordRequest = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null)
      if (email == undefined || email == "") {
        console.log("ff")
        setError("Missing email")
        return
      } 
      try {
        const response = await axios.post(APISettings.USERS_RESET_PASSWORD_REQUEST_URL,
          JSON.stringify({ email: email }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );
      } catch (err) {
        if (!err?.response) {
          setError("No Server Response")
        } else if (err.response?.status === 400) {
          setError("Missing email")
        } else if (err.response?.status === 401) {
          setError("Unauthorized")
        } else {
          setError("Login Failed. Wrong username or password")
        }
      }

      setConfirmShow(true)
    }
    
    /* Confirm delete member modal */
      
    const [showConfirm, setConfirmShow] = useState(false);
    const handleConfirmClose = () => setConfirmShow(false);
    const handleConfirmShow = () => setConfirmShow(true);
    
    function handleConfirmNo() {
      navigate("/");
    }
    
    function handleConfirmYes() {
      navigate("/");
    }

    return (
        <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card id="loginCard" className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 ">Forgot Password</h2>
                  <p className="mb-2">Please enter your email!</p>
                  <div className="mb-3">
                    <Form onSubmit={(handleSubmit)}>
                      {error == null ? "" : <Form.Label className="text-danger">{error}</Form.Label>}
                      <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
                        <Form.Label className="text-center fw-bold">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </Form.Group>
                      <div className="d-grid mt-4">
                        <Button variant="primary" type="submit" className="mb-2">
                          Forgot password
                        </Button>
                      </div>
                      <div className="d-grid">
                        <p className="small text-end m-0 p-0">
                          <a className="text-primary m-0 p-0 ms-2" href="/">
                            Remember password?
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showConfirm} onHide={handleConfirmClose} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title><h5 className="mb-0">Info</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-7">
          We sent you and email. Follow the instructions for password reset.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="fs-7" onClick={handleConfirmYes}>
              Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    )
}

export default PasswordRequest