import APISettings from "./APISettings";

const APIRequestAction = {
  get: 0,
  delete: 1,
  post: 2,
  patch: 3
}

class APICalls { 
  constructor(axiosPrivate, navigate, location) {
    this.axiosPrivate = axiosPrivate;
    this.navigate = navigate;
    this.location = location;
  }

  /* COMMON */

  check401Error(err, callback) {
    //When refresh token expires require user to login again.
    //Then navigate back to where they came from
    if (err?.response?.status === 401) {
      this.navigate('/login', { state: { from: this.location }, replace: true});
    }
    else {
      let error = err?.response?.data?.error
      if (error == undefined || error == null || error == "") {
        error = err?.response.data
        if (error == undefined || error == null || error == "") {
          error = "Unknown error"
        }
      }
      callback(error, null)
    }
  }

  async request(action, url, params, callback) {
    //console.log("-----");
    console.log(url);
    /*console.log(params);
    console.log("-----");*/
    switch (action) {
      case APIRequestAction.get:
        try {
          const response = await this.axiosPrivate.get(url);
          callback(null, response.data)
        } catch (err) {
          this.check401Error(err, callback);
        }
        break;
      case APIRequestAction.delete:
        try {
          const response = await this.axiosPrivate.delete(url)
          callback(null, response.data)
        } catch (err) {
          this.check401Error(err, callback);
        }
        break;
      case APIRequestAction.post:
        try {
          const response = await this.axiosPrivate.post(url, params);
          callback(null, response.data)
        } catch (err) {
          this.check401Error(err, callback);
        }
        break;
      case APIRequestAction.patch:
        try {
          const response = await this.axiosPrivate.patch(url, params);
          callback(null, response.data)
        } catch (err) {
          this.check401Error(err, callback);
        }
        break;
    }
  }

  /* NOTIFICATION FORWARDS */

  notificationForwards() {
    return {
      get: async (bundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.NOTIFICATION_FORWARDS_URL + "/" + bundleId, null, callback);
      },
      delete: async (id, callback) => {
        await this.request(APIRequestAction.delete, APISettings.NOTIFICATION_FORWARDS_URL + "/" + id, null, callback);
      },
      insert: async (name, appBundleId, url, callback) => {
        await this.request(APIRequestAction.post, APISettings.NOTIFICATION_FORWARDS_URL, {name, appBundleId, url}, callback);
      },
      update: async (id, name, appBundleId, url, callback) => {
        await this.request(APIRequestAction.patch, APISettings.NOTIFICATION_FORWARDS_URL + "/" + id, {name, appBundleId, url}, callback);
      }
    };
  }

  /* USERS */

  users() {
    return {
      me: async (callback) => {
        await this.request(APIRequestAction.get, APISettings.CURRENT_USER_URL, null, callback);
      },
      delete: async (email, callback) => {
        await this.request(APIRequestAction.delete, APISettings.USERS_URL + "?email=" + email, null, callback);
      },
      update: async (email, name, role, newEmail, callback) => {
        await this.request(APIRequestAction.patch, APISettings.USERS_URL, {email, name, role, newEmail}, callback);
      },
      all: async (callback) => {
        await this.request(APIRequestAction.get, APISettings.USERS_URL, null, callback);
      },
      invite: async (email, name, role, callback) => {
        await this.request(APIRequestAction.post, APISettings.USERS_URL, {email, name, role}, callback);
      },
      resetPasswordRequest: async (email, callback) => {
        await this.request(APIRequestAction.post, APISettings.USERS_RESET_PASSWORD_REQUEST_URL, {email}, callback);
      },
      passwordReset: async (password, callback) => {
        await this.request(APIRequestAction.post, APISettings.USERS_RESET_PASSWORD_URL, {password}, callback);
      }
    };
  }

  /* REPORTS */

  reports() {
    return {
      getUsers: async (skip, count, appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.REPORTS_USERS_URL + "?skip=" + skip + "&count=" + count + "&appBundleId=" + appBundleId, null, callback);
      },
      getUserInfo: async (userEmail, userId, appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.REPORTS_USER_INFO_URL + "?" + (userEmail != null && userEmail != "" ? "email=" + userEmail : "") + (userId != null && userId != "" ? "userId=" + userId : "") + "&appBundleId=" + appBundleId, null, callback);
      },
      dashboard: async(startDate, endDate, appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.REPORTS_DASHBOARD_URL + "?startDate=" + startDate + "&endDate=" + endDate + "&appBundleId=" + appBundleId, null, callback);
      },
      propertyConditions: async(propertyConditions, groupBy, appBundleId, callback) => {
        await this.request(APIRequestAction.post, APISettings.REPORTS_PROPERTY_CONDITIONS_URL, (propertyConditions == null ? {appBundleId, groupBy} : (groupBy == null ? {appBundleId, propertyConditions} : {appBundleId, propertyConditions, groupBy})), callback);
      }
    };
  }

  /* APPS */

  apps() {
    return {
      get: async (callback) => {
        await this.request(APIRequestAction.get, APISettings.APPS_URL, null, callback);
      },
      getAvailable: async (callback) => {
        await this.request(APIRequestAction.get, APISettings.APPS_AVAILABLE_URL, null, callback);
      },
      activate: async (appBundleId, callback) => {
        await this.request(APIRequestAction.post, APISettings.APPS_ACTIVATE_URL + "?bundleId=" + appBundleId, {}, callback);
      },
      deactivate: async (appBundleId, callback) => {
        await this.request(APIRequestAction.post, APISettings.APPS_DEACTIVATE_URL + "?bundleId=" + appBundleId, {}, callback);
      },
      update: async (appBundleId, name, callback) => {
        await this.request(APIRequestAction.post, APISettings.APPS_URL + "?bundleId=" + appBundleId, {name}, callback);
      }
    };
  }

  /* PROPERTIES */

  properties() {
    return {
      list: async (appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.PROPERTIES_LIST_URL + "?appBundleId=" + appBundleId, null, callback);
      },
      propertyValues: async (appBundleId, propertyKey, callback) => {
        await this.request(APIRequestAction.get, APISettings.PROPERTIES_PROPERTY_VALUES_URL + "?appBundleId=" + appBundleId + "&propertyKey=" + propertyKey, null, callback);
      },
      propertyReportsList: async (appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.PROPERTIES_PROPERY_REPORT_LIST_URL + "?appBundleId=" + appBundleId, null, callback);
      },
      getPropertyReport: async (id, callback) => {
        await this.request(APIRequestAction.get, APISettings.PROPERTIES_PROPERTY_REPORT + "?id=" + id, null, callback);
      },
      savePropertyReport: async (data, callback) => {
        await this.request(APIRequestAction.post, APISettings.PROPERTIES_PROPERTY_REPORT, data, callback);
      },
      updatePropertyReport: async (id, data, callback) => {
        await this.request(APIRequestAction.patch, APISettings.PROPERTIES_PROPERTY_REPORT + "?id=" + id, data, callback);
      },
      deletePropertyReport: async (id, callback) => {
        await this.request(APIRequestAction.delete, APISettings.PROPERTIES_PROPERTY_REPORT + "?id=" + id, null, callback);
      },
    };  
  }

  /* SEARCH ADS */

  searchads() {
    return {
      organizations: async (appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.SEARCHADS_ORGANIZATIONS_URL + "?appBundleId=" + appBundleId, null, callback);
      },
      campaigns: async (appBundleId, callback) => {
        await this.request(APIRequestAction.get, APISettings.SEARCHADS_CAMPAIGNS_URL + "?appBundleId=" + appBundleId, null, callback);
      },
      campaignsReport: async (appBundleId, startDate, endDate, campaigns, organizations, callback) => {
        await this.request(APIRequestAction.get, APISettings.SEARCHADS_CAMPAIGNS_REPORT_URL + "?appBundleId=" + appBundleId + "&startDate=" + startDate + "&endDate=" + endDate + (campaigns != null ? "&campaigns=" + campaigns : "") + (organizations != null ? "&organizations=" + organizations : ""), null, callback);
      },
      keywordsReport: async (appBundleId, startDate, endDate, campaigns, organizations, callback) => {
        await this.request(APIRequestAction.get, APISettings.SEARCHADS_KEYWORDS_REPORT_URL + "?appBundleId=" + appBundleId + "&startDate=" + startDate + "&endDate=" + endDate + (campaigns != null ? "&campaigns=" + campaigns : "") + (organizations != null ? "&organizations=" + organizations : ""), null, callback);
      }
    };
  }

  /* TOOLS */

  tools() {
    return {
      transactionHistory: async (appBundleId, originalTransactionId, callback) => {
        await this.request(APIRequestAction.get, APISettings.TOOLS_TRANSACTION_HISTORY + "/" + originalTransactionId + "?bundleId=" + appBundleId, null, callback);
      },
      verifyReceipt: async (receipt, callback) => {
        await this.request(APIRequestAction.post, APISettings.TOOLS_VERIFY_RECEIPT, {receipt}, callback);
      }
    };
  }
}

export default APICalls