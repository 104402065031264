import React from "react";
import {Chart as ChartJS} from "chart.js/auto"
import { Line } from "react-chartjs-2";

function LineChart({chartData, className, tooltipItemSufix}) {
    
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
    
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.transform = 'translate(-50%, 0)';
          tooltipEl.style.transition = 'all .1s ease';
          tooltipEl.style.width = "fit-content";
      
          const table = document.createElement('table');
          table.style.margin = '0px';
          table.style.marginBottom = "5px";
          table.style.marginTop = "5px";
    
          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
      };
      
      const externalTooltipHandler = (context) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        const tooltipEl = getOrCreateTooltip(chart);
      
        const yAlign = tooltip.yAlign;
        const xAlign = tooltip.xAlign;
    
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map(b => b.lines);
      
          const tableHead = document.createElement('thead');
      
          titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;
            tr.style.fontSize = "12px";
    
            const th = document.createElement('th');
            th.style.borderWidth = 0;
           
            const text = document.createTextNode(title);
      
            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
          });
      
          const tableBody = document.createElement('tbody');
          bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];
      
            const span = document.createElement('span');
            span.style.background = colors.borderColor;
            span.style.borderColor = colors.borderColor;
      
            span.style.borderWidth = '5px';
            span.style.marginRight = '5px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';
      
            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;
            tr.style.fontSize = "12px";
    
            //td 1
            const td1 = document.createElement('td');
            td1.style.borderWidth = 0;
            td1.style.textAlign = "right";
            td1.style.paddingLeft = "10px";
            td1.style.fontWeight = "bold"
         
            const split1 = body[0].split(": ")

            const text1 = document.createTextNode(split1[1] + (tooltipItemSufix == undefined ? "" : " " + tooltipItemSufix));
    
            td1.appendChild(span);
            td1.appendChild(text1);
    
            //td 2
            const td2 = document.createElement('td');
            td2.style.borderWidth = 0;
    
            const split2 = body[0].split(": ")
       
            const text2 = document.createTextNode(split2[0]);
            
            td2.appendChild(span);
            td2.appendChild(text2);
        
            tr.appendChild(td2);
            tr.appendChild(td1);
            tableBody.appendChild(tr);
          });
      
          const tableRoot = tooltipEl.querySelector('table');
      
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }
      
          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
        
        // Tooltip height and width
        const { height, width } = tooltipEl.getBoundingClientRect();
    
        // Chart canvas positions
        const positionY = chart.canvas.offsetTop;
        const positionX = chart.canvas.offsetLeft;
    
        // Carets
        const caretY = tooltip.caretY;
        const caretX = tooltip.caretX;
    
        // Final coordinates
        let top = positionY + caretY - height;
        let left = positionX + caretX - width / 2;
        let space = 0; // This for making space between the caret and the element.
    
        // yAlign could be: `top`, `bottom`, `center`
        if (yAlign === "top") {
            top += height + space;
        } else if (yAlign === "center") {
            top += height / 2;
        } else if (yAlign === "bottom") {
            top -= space;
        }
        // xAlign could be: `left`, `center`, `right`
        if (xAlign === "left") {
            left = left + width / 2 - tooltip.xPadding - space / 2;
            if (yAlign === "center") {
            left = left + space * 2;
            }
        } else if (xAlign === "right") {
            left -= width / 2;
            if (yAlign === "center") {
            left = left - space;
            } else {
            left += space;
            }
        }
    
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
    
        // Left and right
        tooltipEl.style.top = `${top}px`;
        tooltipEl.style.left = `${left}px`;
    
        // Font
        tooltipEl.style.font = tooltip.options.bodyFont.string;
    
        // Paddings
        tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px";
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        //aspectRatio: (window.innerWidth < 576 ? 2 : 3),
        interaction: {
            intersect: false,
            mode: 'index',
        }, 
        plugins: {
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler
            }
        }
    }

    const plugins = [{
        afterDraw: chart => {
            if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
                ctx.stroke();
                ctx.restore(); 
            }
        }
    }]

    return (
        <div className="container-fluid">
            <div className="row">
                <Line className={className} data={chartData} options={options} plugins={plugins} />
            </div>
        </div>
        
    )
}

export default LineChart