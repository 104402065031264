import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APISettings from "../api/APISettings";

import useAuth from "../hooks/useAuth";
import APICalls from "../api/APICalls.js";

import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import Image from "react-bootstrap/Image";
import { AppContext } from "../context/AppProvider.js";
import { PersonCircle } from 'react-bootstrap-icons';
import { Card, NavDropdown } from 'react-bootstrap';
import { ROLES } from "../App.js";

const Topbar = () => {
  const { apps, app, userData, setApps, setApp, setUserData} = useContext(AppContext)

  const { setAuth } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const apiCalls = new APICalls(axiosPrivate, navigate, location)

  function setSelectedApp(app) {
    localStorage.setItem('selectedApp', JSON.stringify(app));
    setApp(app)
  }

  // fetch data
  const dataFetch = async () => {
    apiCalls.apps().get((err, data) => {
      if (err) {
      } else {
        setApps(data);

        var selectedBundleId = JSON.parse(localStorage.getItem("selectedApp"))
        if (selectedBundleId == null) {
          if (data.length > 0) {
            setSelectedApp(data[0])
          }
        } else {
          const found = data.find(obj => {
            return obj.bundleId === selectedBundleId.bundleId;
          });
          if (found) {
            setSelectedApp(found)
          } else {
            if (data.length > 0) {
              setSelectedApp(data[0])
            }
          }
        }
      }
    })
  };

  useEffect(() => {
      dataFetch();
  }, []);

  const handleAppSelect = (eventkey) => {
    const found = apps.find(obj => {
      return obj.bundleId === eventkey;
    });
    if (found) {
      localStorage.setItem('selectedApp', JSON.stringify(found));
      setApp(found)
    }
  }

  const handleMenuClick = (e) => {
    var sidebar = document.getElementsByClassName("sidebar");
    sidebar[0].className = sidebar[0].className === "sidebar toggled" ? "sidebar" : "sidebar toggled";
  }

  const handleProfileSettingsClicked = (e) => {
    navigate("/settings");
  }

  const handleProfileLogoutClicked = async (e) => {
    e.preventDefault();
    try {
        const response = await axiosPrivate.get(APISettings.LOGOUT_URL,
            JSON.stringify({ }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );

        console.log(response);
        setAuth({ })

        navigate("/login");
      } catch (err) {
        if (!err?.response) {
            console.log("No Server Response");
        } else if (err.response?.status === 400) {
            console.log("Missing Username or Password");
        } else if (err.response?.status === 401) {
            console.log("Unauthorized");
        } else {
            console.log("Login Failed");
        }
    }
  }

const UserMenu = (
  <PersonCircle role="button" size={40} className="fa-icon order-2 order-sm-3 mx-0 mx-sm-2"/>
)

return ( 
    <div className="topbar">
      <nav className="navbar navbar-dark bg-dark navbar-fixed-top">
        <div className="container-fluid">

          <div className="navbar-brand order-1 order-sm-1 mx-0 mx-sm-2">
            <img src="topappLogo.png" width="38" height="38" alt=""/>
            <a href="/" className="text-light ms-3 text-decoration-none">
              <span className="fs-5">TAT Dashboard</span>
            </a>
          </div>

          <NavDropdown id="nav-dropdown" title={UserMenu} className="order-2 order-sm-3">
            <NavDropdown.Header className='text-dark'>
            <div className="d-flex flex-row fs-7">
              <div className=""><Image src="avatar.png" className="me-3 mt-2" style={{width:60 + "px"}} rounded/></div>
              <div className="">
                <p className="fs-5 fw-bold m-0">{userData?.name}</p>
                  <p className="m-0">Email: {userData?.email}</p>
                  {(userData.role == ROLES.Admin) ? (
                    <p className="m-0">Role: Admin</p>
                  ) : (
                    <p className="m-0">Role: Viewer</p>
                  )}  
                </div>
            </div>
            </NavDropdown.Header>
            <NavDropdown.Divider className="m-0"/>
            <NavDropdown.Item eventKey="settings" onClick={handleProfileSettingsClicked}>
              <p className='text-dark my-2 fs-7'>Settings</p>
              {/*<button type='button' className='btn btn-primary w-100 my-2' onClick={handleProfileSettingsClicked}>Settings</button>*/}
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="logout" onClick={handleProfileLogoutClicked}>
              <p className='text-dark my-2  fs-7'>Logout</p>
              {/*<button type='button' className='btn btn-primary w-100 my-2 mb-0' onClick={handleProfileLogoutClicked}>Logout</button>*/}
            </NavDropdown.Item>
          </NavDropdown>

          <div className="w-100 order-3 d-block d-sm-none mt-1 mb-2"></div>

          <button className="navbar-toggler order-4" type="button" onClick={handleMenuClick}>
            <span className="navbar-toggler-icon"></span>
          </button>  
        
          <Dropdown className='order-5 order-sm-2' onSelect={handleAppSelect}>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className="fs-7" style={{minWidth: 150 + "px"}}>
            <img src={"data:image/png;base64," + app?.icon} width="30" height="30" className="rounded-20pc bi me-3" />{app?.appName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {apps?.map((data) => (
              <Dropdown.Item key={data.bundleId} eventKey={data.bundleId} title={data.appName} className="fs-7"><Image src={"data:image/png;base64,"+data.icon} width="40" height="40" className="rounded-20pc bi me-3"/>{data.appName}</Dropdown.Item>
            ))}
            </Dropdown.Menu>
          </Dropdown>    
        </div>
      </nav>
    </div>
  )};
  
  export default Topbar;