import React from "react";
import moment from 'moment';
import LineChart from "./LineChart";
import { Colors } from 'chart.js';
import {Chart as ChartJS} from "chart.js/auto"

ChartJS.register(Colors);

function RevenueChart({data, filter}) {
    //Init records 
    let records = []

    var totalOrganic = 0
    var totalPurchased = 0
    var totalAll = 0

    //Init all records with date
    var startDate = moment(filter.startDate);
    var endDate = moment(filter.endDate);
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        records.push({date: m.format("YYYY-MM-DD"), organic: 0, purchased: 0, all: 0})
    }

    //Add all active users data
    data?.days?.forEach(days => {
        var date = days.date
        var index = records.findIndex(obj => {
            return obj.date === date;
        });
           
        if (index >= 0) {
            var organic = 0
            var purchased = 0
            var all = 0

            let filteredCountries = days.countries
            if (filter.country != "All") {
                filteredCountries = days.countries?.filter(obj => obj.country === filter.country)
            }

            filteredCountries.forEach(country => {
                var tempOrganic = country.newOrganicUsers;
                var tempPurchased = country.newPurchasedUsers;
                var tempAll = tempOrganic + tempPurchased;

                organic += tempOrganic
                purchased += tempPurchased
                all += tempAll

                totalOrganic += tempOrganic
                totalPurchased += tempPurchased
                totalAll += tempAll
            })
            records[index] = {date: date, organic: organic, purchased: purchased, all: all}
        }  
    });

    //Get all charts labels and data
    let chartData = {
        labels: records?.map(item => moment(item.date).format("DD.MM.YY")),
        datasets: [
            {
                label: ["All"],
                data: records?.map(item => item.all.toFixed(2))
            },
            {
                label: ["Organic"],
                data: records?.map(item => item.organic.toFixed(2)),
            },
            {
                label: ["Purchased"],
                data: records?.map(item => item.purchased.toFixed(2))
            }
        ]
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row"> 
                    <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">New Users</h5></div>  
                    <div className="col-6 d-flex align-items-center justify-content-end"><h6 className="mb-0">Total: {totalAll}</h6></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart  chartData={chartData} className={"medium-chart-container"}/>
            </div>
            <div className="card-footer">
                <div className="d-flex flex-wrap">
                    <div className="col-4 text-center"><p className="fw-normal m-0 fs-7">All: <p className="fw-bold m-0">{totalAll}</p></p></div>
                    <div className="col-4 text-center"><p className="fw-normal m-0 fs-7">Organic: <p className="fw-bold m-0">{totalOrganic}</p></p></div>
                    <div className="col-4 text-center"><p className="fw-normal m-0 fs-7">Purchased: <p className="fw-bold m-0">{totalPurchased}</p></p></div>
                </div>
            </div>
    </div>
    )
}

        {/*<div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6"><h4 className="card-header-title float-start mb-0">Revenue</h4></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart  chartData={chartData} />
            </div>
            <div className="card-footer">
                <div className="d-flex flex-wrap mt-3">
                
                    <div className="col-4 col-xl-2 text-center"><p>Organic: <p className="fw-bold">{totalOrganic.toFixed(2)} €</p></p></div>
                    <div className="col-4 col-xl-2 text-center"><p>Purchased: <p className="fw-bold">{totalPurchased.toFixed(2)} €</p></p></div>
                    <div className="col-4 col-xl-2 text-center"><p>Conversion: <p className="fw-bold">{totalConversion.toFixed(2)} €</p></p></div>
                    <div className="col-4 col-xl-2 text-center"><p>Renewal: <p className="fw-bold">{totalRenewal.toFixed(2)} €</p></p></div>
                    <div className="col-4 col-xl-2 text-center"><p>Refunds: <p className="fw-bold">{totalRefunds.toFixed(2)} €</p></p></div>
                    <div className="col-4 col-xl-2 text-center"><p>All: <p className="fw-bold">{totalAll.toFixed(2)} €</p></p></div>
                </div>
            </div>
    </div>*/}

export default RevenueChart