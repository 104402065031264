import React, { useContext } from "react";

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import APICalls from "../api/APICalls.js";

import { PencilFill, TrashFill } from 'react-bootstrap-icons';

import { AppContext } from "../context/AppProvider.js";
import { ROLES } from "../App.js";

const AppSettings = (props) => {
    const { app, userData } = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    /* Add modal */
    
    const [showAdd, setAddShow] = useState(false);
    const [editId, setEditId] = useState(-1);
    const [deleteId, setDeleteId] = useState(-1);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [errorAdd, setErrorAdd] = useState(null);

    const modalAddClose = () => {
        setEditId(-1)
        setName("")
        setUrl("")
        setAddShow(false);
    }

    const modalAddShow = () => {
        setErrorAdd(null)
        setAddShow(true);
    }

    /* Confirm modal */

    const [showConfirm, setConfirmShow] = useState(false);
    const handleConfirmClose = () => setConfirmShow(false);
    const handleConfirmShow = () => setConfirmShow(true);

    /* Data */

    const [data, setData] = useState();

    const dataFetch = async () => {
        apiCalls.notificationForwards().get(app.bundleId, (err, data) => {
          setData(data)
        })
    };
    
    useEffect(() => {
        dataFetch();
    }, []);

    function handleEdit(item) {
        setEditId(item.id)
        setName(item.name)
        setUrl(item.url)
        modalAddShow();
    }

    function handleDelete(id) {
        setDeleteId(id)
        setConfirmShow(true)
    }

    function handleAdd() {
        if (editId == -1) {
            apiCalls.notificationForwards().insert(name, app.bundleId, url, (err, data) => {
                if (err) {
                    setErrorAdd(err)
                } else {
                    modalAddClose()
                    setEditId(-1)
                    setName("")
                    setUrl("")
                    dataFetch()
                }
            })

        } else {
            apiCalls.notificationForwards().update(editId, name, app.bundleId, url, (err, data) => {
                dataFetch()
            })
            modalAddClose()
            setEditId(-1)
            setName("")
            setUrl("")
        }
    }

    function handleConfirmNo() {
        setConfirmShow(false)
    }

    function handleConfirmYes() {
        apiCalls.notificationForwards().delete(deleteId, (err , data) => {
            setConfirmShow(false);
            dataFetch();
        })
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <h4>Settings</h4>

                <h5 className="mt-5">Notifications forwards</h5>
                <table className="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th scope="col" className="fs-7 fw-bold">Name</th>
                        <th scope="col" className="fs-7 fw-bold">Url</th>
                        {(userData.role == ROLES.Admin) ? (
                            <th scope="col" className="fs-7 fw-bold">Actions</th>
                        ) : (
                            <></>
                        )}    
                    </tr>
                </thead>
                <tbody>
                    {data?.map(item => 
                    <tr key={item?.id}> 
                        <td className="fs-7 align-middle fs-7">{item.name}</td>
                        <td className="fs-7 align-middle fs-7"><span style={{wordBreak:"break-all"}}>{item.url}</span></td>
                        {(userData.role == ROLES.Admin) ? (
                            <td className="fs-7 align-middle">
                                <button type="button" className="btn btn-success me-2 fs-7" onClick={() => handleEdit(item)}><PencilFill color="white" /></button>
                                <button type="button" className="btn btn-danger fs-7" onClick={() => handleDelete(item.id)}><TrashFill color="white" /></button>
                            </td>
                        ) : (
                            <></>
                        )}             
                    </tr>
                    )}
                </tbody>
                </table>
                {(userData.role == ROLES.Admin) ? (
                    <>
                        <button type="button" className="btn btn-primary mt-3 fs-7" onClick={modalAddShow}>Add</button>
                    </>
                ) : (
                     <></>
                )}    

                <Modal show={showAdd} onHide={modalAddClose}>
                    <Modal.Header closeButton>
                    <Modal.Title ><h5 className="mb-0">{editId != -1 ? "Edit notification forward" : "Add notification forward"}</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                        {errorAdd == null ? "" : <Form.Label className="text-danger">{errorAdd}</Form.Label>}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-7">Name</Form.Label>
                        <Form.Control
                            className="fs-7"
                            type="text"
                            placeholder="Name"
                            autoFocus
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            required="true"
                        />
                        </Form.Group>
                        <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput2"
                        >
                        <Form.Label className="fs-7">Url</Form.Label>
                        <Form.Control
                            className="fs-7"
                            type="url"
                            placeholder="Url"
                            value={url} 
                            onChange={(e) => setUrl(e.target.value)}
                            required="true"
                        />
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="fs-7" onClick={modalAddClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" className="fs-7" onClick={handleAdd}>
                        {editId != -1 ? "Save changes" : "Add"}
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfirm} onHide={handleConfirmClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><h5>Delete</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="fs-7">
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer >
                    <Button variant="secondary" className="fs-7" onClick={handleConfirmNo}>
                        No, Gracias
                    </Button>
                    <Button variant="primary" className="fs-7" onClick={handleConfirmYes}>
                        Si Señor 🍕
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default AppSettings