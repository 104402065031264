import React from "react";

import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APISettings from "../api/APISettings";

const Admin = (props) => {
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="admin">
                    <h2>You must be an admin to see this page!!</h2>
                </div>
            </div>
        </div>
    )
}

export default Admin