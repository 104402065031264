import React from "react";
import { useMemo, useState } from "react";
import { SortDown, SortUp } from 'react-bootstrap-icons';

const useSortableData = (items, config = null) => {
    console.log("items")
    console.log(items)

    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
        ) {
        direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};


const KeywordsTable = ({data, total}) => {
    var dataNew = []
    {data == null ? 
        dataNew = []
        :
        dataNew = data
    }

    //Sort data
    dataNew?.forEach(item => {
        item.user_to_trial = (item?.installs != 0 ? (item?.trials / item?.installs * 100) : 0) 
        item.user_to_subs = (item?.installs != 0 ? (item?.trialsConverted / item?.installs * 100) : 0)
        item.user_to_paid = (item?.trials != 0 ? (item?.trialsConverted / item?.trials * 100) : 0)
        item.arpu = (item?.installs != 0 ? (item?.estProceeds / item?.installs) : 0)
        item.roas = (item?.spend != 0 ? (item?.estProceeds / item?.spend * 100) : 0)
    });

    const { items, requestSort, sortConfig } = useSortableData(dataNew);
    const getSortIcon = (name) => {
        if (!sortConfig || sortConfig.key !== name) {
            return undefined;
        }

        return sortConfig.direction == 'ascending' ? <SortUp style={{marginLeft:5}} size={18} /> : <SortDown  style={{marginLeft:5}} size={18} />
    }

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return "mb-0";
        }
        return sortConfig.key === name ? sortConfig.direction + " mb-0": undefined + " mb-0"; 
    };
    return (
        <table className="table table-bordered tableSearchAds mt-3">
            <thead className="header">
                <tr>
                    <th scope="col" className="bg-header-table fs-7 fw-bold sticky-col first-col"><p type="button" onClick={() => requestSort('keyword')} className={getClassNamesFor('keyword')}>Keyword {getSortIcon('keyword')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('campaignName')} className={getClassNamesFor('campaignName')}>Campaign {getSortIcon('campaignName')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('impressions')} className={getClassNamesFor('impressions')}>Impressions {getSortIcon('impressions')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('taps')} className={getClassNamesFor('taps')}>Taps {getSortIcon('taps')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('installs')} className={getClassNamesFor('installs')}>Installs {getSortIcon('installs')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('trials')} className={getClassNamesFor('trials')}>Trials {getSortIcon('trials')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('trialsConverted')} className={getClassNamesFor('trialsConverted')}>Trials Conv {getSortIcon('trialsConverted')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('purchases')} className={getClassNamesFor('purchases')}>Purchases {getSortIcon('purchases')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('user_to_trial')} className={getClassNamesFor('user_to_trial')}>User to trial {getSortIcon('user_to_trial')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('user_to_subs')} className={getClassNamesFor('user_to_subs')}>User to subs. {getSortIcon('user_to_subs')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('user_to_paid')} className={getClassNamesFor('user_to_paid')}>User to paid {getSortIcon('user_to_paid')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('arpu')} className={getClassNamesFor('arpu')}>ARPU {getSortIcon('arpu')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('avgCPA')} className={getClassNamesFor('avgCPA')}>Avg. CPA {getSortIcon('avgCPA')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('avgCPT')} className={getClassNamesFor('avgCPT')}>Avg. CPT {getSortIcon('avgCPT')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('roas')} className={getClassNamesFor('roas')}>ROAS {getSortIcon('roas')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('spend')} className={getClassNamesFor('spend')}>Spend {getSortIcon('spend')}</p></th>
                    <th scope="col" className="bg-header-table fs-7 fw-bold table-col"><p type="button" onClick={() => requestSort('estProceeds')} className={getClassNamesFor('estProceeds')}>Est. Proceeds {getSortIcon('estProceeds')}</p></th>                                           
                </tr>
                <tr>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold sticky-col first-col">Total</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.campaignName}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.impressions}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.taps}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.installs}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.trials}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.trialsConverted}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.purchases}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(total?.installs != 0 ? (total?.trials / total?.installs * 100) : 0).toFixed(2)  + " %"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(total?.installs != 0 ? (total?.trialsConverted / total?.installs * 100) : 0).toFixed(2) + " %"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(total?.trials != 0 ? (total?.trialsConverted / total?.trials * 100) : 0).toFixed(2) + " %"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(total?.installs != 0 ? (total?.estProceeds / total?.installs) : 0).toFixed(2) + " €"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.avgCPA.toFixed(2) + " €"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.avgCPT.toFixed(2) + " €"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(total?.spend != 0 ? (total?.estProceeds / total?.spend * 100) : 0).toFixed(2) + " %"}</th>
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.spend.toFixed(2) + " €"}</th>                                                
                    <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total?.estProceeds.toFixed(2)  + " €"}</th>                                               
                </tr>
            </thead>
            <tbody>
                {items?.map(item => 
                    <tr key={item.keyword}> 
                        <td className="fs-7 fw-bold align-middle sticky-col first-col">{item?.keyword}</td>  
                        <td className="fs-7 fw-bold align-middle table-col">{item?.campaignName}</td>  
                        <td className="fs-7 fw-bold align-middle table-col">{item?.impressions}</td>   
                        <td className="fs-7 fw-bold align-middle table-col">{item?.taps}</td>  
                        <td className="fs-7 fw-bold align-middle table-col">{item?.installs}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.trials}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.trialsConverted}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.purchases}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.user_to_trial.toFixed(2)  + " %"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.user_to_subs.toFixed(2) + " %"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.user_to_paid.toFixed(2) + " %"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.arpu.toFixed(2) + " €"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPA.toFixed(2) + " €"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPT.toFixed(2) + " €"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.roas.toFixed(2) + " %"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.spend.toFixed(2) + " €"}</td> 
                        <td className="fs-7 fw-bold align-middle table-col">{item?.estProceeds.toFixed(2)  + " €"}</td>                                               
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default KeywordsTable;