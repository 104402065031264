import React, { useState } from "react";

const Register = (props) => {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [name, setName] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("email: " + email + ", password:" + pass);
    }

    return (
        <div className="register">
            <div className="auth-form-container">
                <h2>Register</h2>
                <form className="register-form" onSubmit={(handleSubmit)}>
                    <label htmlFor="name">Full name</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" placeholder="full name"></input>
                    <label for="email">Email</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="email@gmail.com" id="email" name="email" />
                    <label for="password">Password</label>
                    <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" />
                    <button className="login-btn" type="submit">Login</button>
                </form>
                <button className="link-btn" onClick={() => props.onFormSwitch("login")}>Already have an account? Login here.</button>
            </div>
        </div>
    )
}

export default Register