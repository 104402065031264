import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const  { auth, setAuth } = useAuth();
  
    const refresh = async () => {
        //console.log(auth)

        let includeUser = (auth.user == null);

        var headers = {}
        headers = { 'Content-Type': 'application/json' }
        
        const response = await axios.post('/api/auth/token/refresh' + (includeUser ? '?includeUser=true' : ''),
            JSON.stringify({  }),
            {
                headers: headers,
                withCredentials: true
            }
        );
        
        setAuth(prev => {
            return { ...prev, 
                user: response?.data?.user ?? prev.user,
                accessToken: response.data?.accessToken
            }
        });
        //console.log(response?.data?.user);
        //console.log(response.data.accessToken);
        return response.data.accessToken;
    }
    return refresh;
}
export default useRefreshToken