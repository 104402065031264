import React from 'react'
import { SidebarData } from './SidebarData'
import { NavLink } from 'react-router-dom'
import { useState } from 'react';


const Sidebar = () => {

  const handleItemClick = (e) => {
    var sidebar = document.getElementsByClassName("sidebar");
    sidebar[0].className = "sidebar";
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <nav id="sidebar" className="sidebar">
      <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{minHeight:"100"+"%"}}>
        <ul className="nav nav-pills flex-column mb-auto">
          {SidebarData.map((val, key) => {
            return (
              <li
                key={key}
                className="nav-item">
                  <NavLink to={val.link} onClick={handleItemClick} className="nav-link d-flex flex-row">
                    <div id="icon" className="bi me-2">{val.icon}</div><p id="title" className="d-flex align-items-center fs-6 mb-0">{val.title}</p>
                  </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  );

  /*return (
    <nav id="sidebar" className="sidebar">
      <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark min-vh-100">
        <ul className="nav nav-pills flex-column mb-auto">
          {SidebarData.map((val, key) => {
            return (
              <li
                key={key}
                className="nav-item">
                  <NavLink to={val.link} onClick={handleItemClick} className="nav-link d-flex flex-row">
                    <div id="icon" className="bi me-2">{val.icon}</div><div id="title">{val.title}</div>
                  </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  );*/
}
export default Sidebar