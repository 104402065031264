import React from "react";
import { CardList, GearFill, GridFill, PersonFill, Search, Tools } from 'react-bootstrap-icons';

export const SidebarData = [
    {
        title: "Dashboard",
        icon: <GridFill size={20} />,
        link: "/dashboard"
    }, 
    {
        title: "Users",
        icon: <PersonFill size={20} />,
        link: "/users"
    }, 
    {
        title: "Properties",
        icon: <CardList size={20} />,
        link: "/properties"
    },
    {
        title: "Search Ads",
        icon: <Search size={20} />,
        link: "/searchAds"
    },
    {
        title: "Tools",
        icon: <Tools size={20} />,
        link: "/tools"
    }, 
    {
        title: "Settings",
        icon: <GearFill size={20} />,
        link: "/appSettings"
    }, 
]