import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import APIAuth from "../api/APIAuth";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';
import APISettings from "../api/APISettings";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const PasswordReset = (props) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")

    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("password:" + password);

        if (password == undefined || password == "") {
          setError("Missing password")
        } else if (password != confirmPassword) {
          setError("Passwords do not match")
          return
        }

        try {
          const response = await axios.post(APISettings.USERS_RESET_PASSWORD_URL,
              JSON.stringify({ password: password }),
              {
                  headers: { 
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}` 
                  },
                  withCredentials: true
              }
          );

          console.log(response?.data);
          navigate("/");
        } catch (err) {
          if (!err?.response) {
            setError("No Server Response")
          } else if (err.response?.status === 400) {
            setError("Missing password")
          } else if (err.response?.status === 401) {
            setError("Unauthorized")
          } else {
            setError("Login Failed. Wrong username or password")
          }
        }
    }

    return (
        <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card id="loginCard" className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 ">Set Password</h2>
                  <p className="mb-2">Please enter password!</p>
                  <div className="mb-3">
                    <Form onSubmit={(handleSubmit)}>
                    {error == null ? "" : <Form.Label className="text-danger">{error}</Form.Label>}
                      <Form.Group className="mb-3 mt-4" controlId="formBasicPassword">
                        <Form.Label className="fw-bold">New Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="fw-bold">Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                      </Form.Group>

                      <div className="d-grid mt-5">
                        <Button variant="primary" type="submit" className="mb-3">
                          Reset Password
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    )
}

export default PasswordReset