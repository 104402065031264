import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import APIAuth from "../api/APIAuth";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';
import APISettings from "../api/APISettings";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { setAuth } = useAuth();
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        try {
            //const response = await APIAuth.login(email, password);
            //const accessToken = response?.accessToken;
            //const user = response?.user;

            const response = await axios.post(APISettings.LOGIN_URL,
                JSON.stringify({ email: email, password: password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const accessToken = response?.data?.accessToken;
            const user = response?.data?.user;

            console.log("accessToken: " + accessToken + ", user: " + user.email);
            setAuth({ accessToken, user })

            navigate(from, { replace: true });
          } catch (err) {
            if (!err?.response) {
              setError("No Server Response")
            } else if (err.response?.status === 400) {
              setError("Missing username or password")
            } else if (err.response?.status === 401) {
              setError("Unauthorized")
            } else {
              setError("Login Failed. Wrong username or password")
            }
          }
    }

    return (
        <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card id="loginCard" className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 ">TAT Dashboard</h2>
                  <p className="mb-2">Please enter your login and password!</p>
                  <div className="mb-3">
                    <Form onSubmit={(handleSubmit)}>
                      {error == null ? "" : <Form.Label className="text-danger">{error}</Form.Label>}
                      <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
                        <Form.Label className="text-center fw-bold">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="fw-bold">Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit" className="mb-2 mt-2">
                          Login
                        </Button>
                      </div>
                      <div className="d-grid">
                        <p className="small text-end m-0 p-0">
                            <a className="text-primary m-0 p-0" href="passwordRequest">
                              Forgot password?
                            </a>
                          </p>
                      </div>
                    </Form>
                    {/*<div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        <a href="{''}" className="text-primary fw-bold">
                          Sign Up
                        </a>
                      </p>
                    </div>*/}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    )
}

export default Login