import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="missing__container">        
            <img src={require("../resources/images/unauthorized.png")} alt="404" title="404" width="500" height="500" className="missing__image"></img>
            <article className="missing__text-container">
                <h1 style={{paddingBottom: "1rem"}}>Unauthorized</h1>
                <p>You do not have access to the requested page.</p>
                <div 
                    className="flexGrow"
                    style={{textDecoration: "none", color: "var(--SELECTED-TEXT-COLOR)", paddingTop: "0.5rem", cursor: "pointer", display: "inline-block"}}
                    onClick={goBack}
                >
                    Go Back
                </div>
            </article>
        </div>
    )
}


export default Unauthorized
