import React from "react";
import moment from 'moment';
import LineChart from "./LineChart";
import { Colors } from 'chart.js';
import {Chart as ChartJS} from "chart.js/auto"

function ProductRevenueChart({data, filter, productId}) {
    //Init records 
    let records = []

    var totalRenewal = 0
    var totalRefund = 0
    var totalConversion = 0
    var totalAll = 0

    //Init all records with date
    var startDate = moment(filter.startDate);
    var endDate = moment(filter.endDate);
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        records.push({date: m.format("YYYY-MM-DD"), all: 0, renewal: 0, refund: 0, conversion: 0})
    }

    //Add all active users data
    data?.days?.forEach(days => {
        var date = days.date
        var index = records.findIndex(obj => {
            return obj.date === date;
        });
           
        if (index >= 0) {
            var renewal = 0
            var refund = 0
            var conversion = 0
            var all = 0

            let filteredCountries = days.countries
            if (filter.country != "All") {
                filteredCountries = days.countries?.filter(obj => obj.country === filter.country)
            }

            filteredCountries.forEach(country => {
                let filteredInAppPurchase = country.inAppPurchases;
                if(productId != undefined) {
                    filteredInAppPurchase = country.inAppPurchases?.filter(obj => obj.productId === productId)
                }

                filteredInAppPurchase?.forEach(inAppPurchase => {
                    inAppPurchase.userTypes?.forEach(userType => {
                        var tempRenewal = userType.renewalRevenue;
                        var tempRefund = userType.refundsAmount;
                        var tempConversion = userType.conversionRevenue;
                        var tempAll = tempRenewal + tempConversion

                        renewal += tempRenewal
                        refund += tempRefund
                        conversion += tempConversion
                        all += tempAll

                        totalRenewal += tempRenewal
                        totalRefund += tempRefund
                        totalConversion += tempConversion
                        totalAll += tempAll
                    })
                })
            })
            records[index] = {date: date, all: all, renewal: renewal, refund: refund, conversion: conversion}
        }  
    });

    //Get all charts labels and data
    let chartData = {
        labels: records?.map(item => moment(item.date).format("DD.MM.YY")),
        datasets: [
            {
                label: ["All"],
                data: records?.map(item => item.all.toFixed(2))
            },
            {
                label: ["Renewals"],
                data: records?.map(item => item.renewal.toFixed(2))
            },
            {
                label: ["Refunds"],
                data: records?.map(item => item.refund.toFixed(2))
            },
            {
                label: ["Conversions"],
                data: records?.map(item => item.conversion.toFixed(2))
            }
        ]
    }
    
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Revenue</h5></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart  chartData={chartData} className={"medium-chart-container"} tooltipItemSufix={"€"}/>
            </div>
            <div className="card-footer">
                <div className="d-flex flex-wrap m-0">
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">All: <p className="fw-bold m-0">{totalAll.toFixed(2)} €</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Conversions: <p className="fw-bold m-0">{totalConversion.toFixed(2)} €</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Renewals: <p className="fw-bold m-0">{totalRenewal.toFixed(2)} €</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Refunds: <p className="fw-bold m-0">{totalRefund.toFixed(2)} €</p></p></div>
                </div>
            </div>
    </div>
    )
}

export default ProductRevenueChart