import React, { useContext } from "react";

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import moment from 'moment';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APICalls from "../api/APICalls.js";
import { AppContext } from "../context/AppProvider.js";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MultiSelectDropdown from "../components/MultiSelectDropdown.jsx";
import { Colors } from "chart.js";
import KeywordsTable from "../components/tables/KeywordsTable.jsx";

const SearchAds = (props) => {
    const {apps, app, setApps, setApp } = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [tab, setTab] = useState('campaigns');

    const [refreshingCampaigns, setRefreshingCampaigns] = useState(true);
    const [refreshingKeywords, setRefreshingKeywords] = useState(true);

    const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(6, "days").format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [dateFilter, setDateFilter] = useState("Custom");

    const [filterCampaigns, setFilterCampaigns] = useState([]);
    const [filterOrganizations, setFilterOrganizations] = useState([]);

    const [totalCampaigns, setTotalCampaigns] = useState(null);
    const [dataCampaigns, setDataCampaigns] = useState(null);
    const [totalKeywords, setTotalKeywords] = useState(null);
    const [dataKeywords, setDataKeywords] = useState(null);
    const [sortedFieldKeywords, setSortedFieldKeywords] = useState(null);

    const [campaigns, setCampaigns] = useState(null);
    const [organizations, setOrganizations] = useState(null);

    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    /* Date */

    function handleDateSelect(eventkey) {
        setDateFilter(eventkey)

        if (eventkey == "Custom") {
            return;
        }

        let dateFrom = moment()
        let dateTo = moment()

        switch(eventkey) {
            case "Today":
                dateFrom = moment()
                dateTo = moment()
                break;
            case "Yesterday":
                dateFrom = moment().subtract(1, "days")
                dateTo = moment().subtract(1, "days")
                break;
            case "Last 7 days":
                dateFrom = moment().subtract(6, "days")
                dateTo = moment()
                break;
            case "Last 30 days":
                dateFrom = moment().subtract(29, "days")
                dateTo = moment()
                break;
            case "This month":
                dateFrom = moment().startOf('month')
                dateTo = moment().endOf('month')
                break;
            case "Last month":
                dateFrom = moment().subtract(1,'month').startOf('month')
                dateTo = moment().subtract(1,'month').endOf('month')
                break;
            case "Last month (-3 days)":
                dateFrom = moment().add(-33, "days")
                dateTo = moment().add(-3, "days")
                break;
        }
        
        setDateFrom(dateFrom.format("YYYY-MM-DD"))
        setDateTo(dateTo.format("YYYY-MM-DD"))

        dataFetchCampaigns(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"), filterCampaigns)
    }

    function dateFromChanged(date) {
        setDateFilter("Custom");
        setDateFrom(date)
    }

    function dateToChanged(date) {
        setDateFilter("Custom");
        setDateTo(date)
    }

    /* Data */

    function reloadData(dateFrom, dateTo, filterCampaigns, filterOrganizations, tab, force = false) {
        if (tab == "campaigns") {
            dataFetchCampaigns(dateFrom, dateTo, filterCampaigns, filterOrganizations, force)
        } else {
            dataFetchKeywords(dateFrom, dateTo, filterCampaigns, filterOrganizations, force)
        }
    }

    const dataFetchCampaigns = async (from, to, filterCampaigns, filterOrganizations, force = false) => {
        if (force == false && dataCampaigns != null) {
            return
        }

        setRefreshingCampaigns(true);

        //Parse filter campaigns
        var filterStringCampaigns = ""
        if (filterCampaigns?.length > 0) {
            var index = 0
            filterCampaigns?.forEach((item) => {
                filterStringCampaigns += (index > 0 ? "," : "") + item.id
                index++
            })
        } 

        //Parse filter organizaions
        var filterStringOrganizations = ""
        if (filterOrganizations?.length > 0) {
            var index = 0
            filterOrganizations?.forEach((item) => {
                filterStringOrganizations += (index > 0 ? "," : "") + item.id
                index++
            })
        } 

        apiCalls.searchads().campaignsReport(app.bundleId, from, to, filterStringCampaigns == "" ? null : filterStringCampaigns, filterStringOrganizations == "" ? null : filterStringOrganizations, (err, data) => {
            setTotalCampaigns(data?.total)
            setDataCampaigns(data?.campaigns)
            setRefreshingCampaigns(false);
        })
    };

    const dataFetchKeywords = async (from, to, filterCampaigns, filterOrganizations, force = false) => {
        if (force == false && dataKeywords != null) {
            return
        }

        setRefreshingKeywords(true);

        //Parse filter keywords
        var filterString = ""
        if (filterCampaigns?.length > 0) {
            var index = 0
            filterCampaigns?.forEach((item) => {
                filterString += (index > 0 ? "," : "") + item.id 
                index++
            })
        } 

        //Parse filter organizaions
        var filterStringOrganizations = ""
        if (filterOrganizations?.length > 0) {
            var index = 0
            filterOrganizations?.forEach((item) => {
                filterStringOrganizations += (index > 0 ? "," : "") + item.id
                index++
            })
        } 

        apiCalls.searchads().keywordsReport(app.bundleId, from, to, filterString == "" ? null : filterString, filterStringOrganizations == "" ? null : filterStringOrganizations, (err, data) => {
            let sordedData = [...data.keywords]
            sordedData.sort((a, b) => {
                if(a.keyword < b.keyword) {
                    return -1
                }
                if (a.keyword > b.keyword) {
                    return 1
                }
                return 0
            })
            
            setTotalKeywords(data?.total)
            setDataKeywords(sordedData)
            setRefreshingKeywords(false);
        })
    };
    
    useEffect(() => {
        dataFetchCampaigns(dateFrom, dateTo, null);

        apiCalls.searchads().campaigns(app.bundleId, (err, data) => {
            let campaigns = []
            data?.campaigns?.forEach(element => {
                campaigns.push({id: element.campaignId, name: element.name})
            });
            setCampaigns(campaigns)
        })

        apiCalls.searchads().organizations(app.bundleId, (err, data) => {
            let organizations = []
            data?.organizations?.forEach(element => {
                organizations.push({id: element.orgId, name: element.displayName})
            });
            setOrganizations(organizations)
        })

    }, []);

    function onOrgFilterChange(items) {
        let newFilter = [...items]
        setFilterOrganizations(newFilter)

        setDataCampaigns(null);
        setDataKeywords(null);

        reloadData(dateFrom, dateTo, filterCampaigns, newFilter, tab, true)
    }

    function onCampFilterChange(items) {
        let newFilter = [...items]
        setFilterCampaigns(newFilter)

        setDataCampaigns(null);
        setDataKeywords(null);

        reloadData(dateFrom, dateTo, newFilter, filterOrganizations, tab, true)
    }

    function onCampaignNameClicked(item) {
        //todo set filters
        setTab("keywords") 

        let items = []
        items.push({id: item.campaignId, name: item.campaignName})
        setFilterCampaigns(items)

        setDataCampaigns(null);
        setDataKeywords(null);

        reloadData(dateFrom, dateTo, items, filterOrganizations, "keywords", true)
    }

    function onOrganizationNameClicked(item) {
        //todo set filters
        setTab("keywords") 

        let items = []
        items.push({id: item.orgId, name: item.orgName})
        setFilterOrganizations(items)

        setDataCampaigns(null);
        setDataKeywords(null);

        reloadData(dateFrom, dateTo, filterCampaigns, items, "keywords", true)
    }

    function onTabChanged(id) {
        setTab(id)
        reloadData(dateFrom, dateTo, filterCampaigns, filterOrganizations, id)
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <h4>Search Ads</h4>
                    {refreshingCampaigns == true ?? <span className="spinner-border spinner-border-md ms-3" role="status"></span>}
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <p className="fs-6 fw-bold">Select date</p>
                                <div className="row col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 mt-3">
                                    <div className="col-12 col-lg-4">
                                        <Form.Control type="date" id="dateFrom" className="fs-7" value={dateFrom} onChange={(e) => dateFromChanged(e.target.value)}></Form.Control>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                                        <Form.Control type="date" id="dateTo" className="fs-7"  value={dateTo} onChange={(e) => dateToChanged(e.target.value)}></Form.Control>
                                    </div>
                                    <div className="col-12 col-lg-2 mt-2 mt-lg-0">
                                        <Button variant="primary" className="w-100 fs-7" onClick={() => reloadData(dateFrom, dateTo, filterCampaigns, filterOrganizations, tab, true)}>
                                            Submit
                                        </Button>
                                    </div> 
                                    <div className="col-12 col-lg-2 mt-2 mt-lg-0">
                                        <Dropdown className='' onSelect={handleDateSelect}>
                                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="fs-7">{dateFilter}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="Custom" key="Custom" title="Custom" className="fs-7">Custom</Dropdown.Item>
                                                {/*<Dropdown.Item eventKey="Today" key="Today" title="Today" className="fs-7">Today</Dropdown.Item>*/}
                                                <Dropdown.Item eventKey="Yesterday" key="Yesterday" title="Yesterday" className="fs-7">Yesterday</Dropdown.Item>
                                                <Dropdown.Item eventKey="Last 7 days" key="Last 7 days" title="Last 7 days" className="fs-7">Last 7 days</Dropdown.Item>
                                                <Dropdown.Item eventKey="Last 30 days" key="Last 30 days" title="Last 30 days" className="fs-7">Last 30 days</Dropdown.Item>
                                                <Dropdown.Item eventKey="This month" key="This month" title="This month" className="fs-7">This month</Dropdown.Item>
                                                <Dropdown.Item eventKey="Last month" key="Last month" title="Last month" className="fs-7">Last month</Dropdown.Item>
                                                <Dropdown.Item eventKey="Last month (-3 days)" key="Last month (-3 days)" title="Last month (-3 days)" className="fs-7">Last month (-3 days)</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> 
                                    </div> 
                                </div>
                                <p className="fs-6 fw-bold mt-3 mb-0">Filter</p>

                                <div className="d-flex flex-column">
                                    <div className="mt-2">
                                        <MultiSelectDropdown name="Organizations" options={organizations} filter={filterOrganizations} onSelectedChange={(items) => onOrgFilterChange(items)}/>
                                        {/*<Dropdown>
                                        <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className="dropdown-toggle-no-carret">
                                            <p className="fs-7 text-primary p-0 m-0">+ Add Organization</p>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="menuitemcheckbox">
                                            {organizations?.map(item => 
                                                <Dropdown.Item eventKey={item.orgName} title={item.orgName} className="fs-7">{item.orgName}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                        </Dropdown> */}
                                    </div> 

                                    <div className="mt-2">
                                        <MultiSelectDropdown className="" name="Campaigns" options={campaigns} filter={filterCampaigns} onSelectedChange={(items) => onCampFilterChange(items)} />
                                        {/*<Dropdown>
                                        <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className="dropdown-toggle-no-carret">
                                            <p className="fs-7 text-primary p-0 m-0">+ Add Campaign</p>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="menuitemcheckbox">
                                            {campaigns?.map(item => 
                                                <Dropdown.Item eventKey={item.campaignName} title={item.campaignName} className="fs-7">{item.campaignName}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                            </Dropdown> */}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="card-body">
                    <Tabs defaultActiveKey="campaigns" id="justify-tab-example" className="mb-3" onSelect={(k) => onTabChanged(k)} activeKey={tab}>
                        <Tab eventKey="campaigns" title="Campaigns">
                            {refreshingCampaigns == true ? 
                                <>
                                    <div className="d-flex justify-content-center align-items-center fs-7">
                                        <span className="spinner-border spinner-border-sm me-3" role="status"></span>
                                        Loading
                                    </div>
                                </> 
                                : 
                                <>
                                <div className="tableContainer">
                                    <table className="table table-bordered mt-3 tableSearchAds">
                                    <thead className="header">
                                        <tr>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold sticky-col first-col">Campaign</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Org. Name</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Impressions</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Taps</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Installs</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Trials</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Trials Conv</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Purchases</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to trial</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to subs.</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to paid</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">ARPU</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Avg. CPA</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Avg. CPT</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">ROAS</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Spend</th>
                                            <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Est. Proceeds</th>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold sticky-col first-col">Total</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col"></th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.impressions}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.taps}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.installs}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.trials}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.trialsConverted}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.purchases}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalCampaigns?.installs != 0 ? (totalCampaigns?.trials / totalCampaigns?.installs * 100) : 0).toFixed(2)  + " %"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalCampaigns?.installs != 0 ? (totalCampaigns?.trialsConverted / totalCampaigns?.installs * 100) : 0).toFixed(2) + " %"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalCampaigns?.trials != 0 ? (totalCampaigns?.trialsConverted / totalCampaigns?.trials * 100) : 0).toFixed(2) + " %"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalCampaigns?.installs != 0 ? (totalCampaigns?.estProceeds / totalCampaigns?.installs) : 0).toFixed(2) + " €"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.avgCPA.toFixed(2) + " €"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.avgCPT.toFixed(2) + " €"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalCampaigns?.spend != 0 ? (totalCampaigns?.estProceeds / totalCampaigns?.spend * 100) : 0).toFixed(2) + " %"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.spend.toFixed(2) + " €"}</th>
                                            <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalCampaigns?.estProceeds.toFixed(2)  + " €"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataCampaigns?.map(item => 
                                        <tr key={item.campaignId}>   
                                            <td className="fs-7 sticky-col first-col text-primary" role="button" onClick={() => onCampaignNameClicked(item)}><u>{item?.campaignName}</u></td>                                            
                                            <td className="fs-7 table-col text-primary" role="button" onClick={() => onOrganizationNameClicked(item)}><u>{item?.orgName ?? item?.orgId}</u></td>
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.impressions}</td>   
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.taps}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.installs}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.trials}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.trialsConverted}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.purchases}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.trials / item?.installs * 100) : 0).toFixed(2)  + " %"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.trialsConverted / item?.installs * 100) : 0).toFixed(2) + " %"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{(item?.trials != 0 ? (item?.trialsConverted / item?.trials * 100) : 0).toFixed(2) + " %"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.estProceeds / item?.installs) : 0).toFixed(2) + " €"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPA.toFixed(2) + " €"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPT.toFixed(2) + " €"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{(item?.spend != 0 ? (item?.estProceeds / item?.spend * 100) : 0).toFixed(2) + " %"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.spend.toFixed(2) + " €"}</td> 
                                            <td className="fs-7 fw-bold align-middle table-col">{item?.estProceeds.toFixed(2) + " €"}</td> 
                                        </tr>
                                        )}
                                    </tbody>
                                    </table>
                                    </div>
                                </>
                            }
                        </Tab>
                        <Tab eventKey="keywords" title="Keywords">
                            {refreshingKeywords == true ? 
                                    <>
                                        <div className="d-flex justify-content-center align-items-center fs-7">
                                            <span className="spinner-border spinner-border-sm me-3" role="status"></span>
                                            Loading
                                        </div>
                                    </> 
                                    : 
                                    <>
                                    <div className="tableContainer">
                                        {/*<table className="table table-bordered tableSearchAds mt-3">
                                        <thead className="header">
                                            <tr>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold sticky-col first-col">Keyword</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Campaign</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Impressions</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Taps</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Installs</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Trials</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Trials Conv</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Purchases</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to trial</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to subs.</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">User to paid</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">ARPU</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Avg. CPA</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Avg. CPT</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">ROAS</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Spend</th>
                                                <th scope="col" className="bg-header-table fs-7 fw-bold table-col">Est. Proceeds</th>                                           
                                            </tr>
                                            <tr>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold sticky-col first-col">Total</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.campaignName}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.impressions}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.taps}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.installs}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.trials}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.trialsConverted}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.purchases}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalKeywords?.installs != 0 ? (totalKeywords?.trials / totalKeywords?.installs * 100) : 0).toFixed(2)  + " %"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalKeywords?.installs != 0 ? (totalKeywords?.trialsConverted / totalKeywords?.installs * 100) : 0).toFixed(2) + " %"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalKeywords?.trials != 0 ? (totalKeywords?.trialsConverted / totalKeywords?.trials * 100) : 0).toFixed(2) + " %"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalKeywords?.installs != 0 ? (totalKeywords?.estProceeds / totalKeywords?.installs) : 0).toFixed(2) + " €"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.avgCPA.toFixed(2) + " €"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.avgCPT.toFixed(2) + " €"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{(totalKeywords?.spend != 0 ? (totalKeywords?.estProceeds / totalKeywords?.spend * 100) : 0).toFixed(2) + " %"}</th>
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.spend.toFixed(2) + " €"}</th>                                                
                                                <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{totalKeywords?.estProceeds.toFixed(2)  + " €"}</th>                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataKeywords?.map(item => 
                                            <tr key={item.keyword}> 
                                                <td className="fs-7 fw-bold align-middle sticky-col first-col">{item?.keyword}</td>  
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.campaignName}</td>  
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.impressions}</td>   
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.taps}</td>  
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.installs}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.trials}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.trialsConverted}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.purchases}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.trials / item?.installs * 100) : 0).toFixed(2)  + " %"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.trialsConverted / item?.installs * 100) : 0).toFixed(2) + " %"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{(item?.trials != 0 ? (item?.trialsConverted / item?.trials * 100) : 0).toFixed(2) + " %"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{(item?.installs != 0 ? (item?.estProceeds / item?.installs) : 0).toFixed(2) + " €"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPA.toFixed(2) + " €"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.avgCPT.toFixed(2) + " €"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{(item?.spend != 0 ? (item?.estProceeds / item?.spend * 100) : 0).toFixed(2) + " %"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.spend.toFixed(2) + " €"}</td> 
                                                <td className="fs-7 fw-bold align-middle table-col">{item?.estProceeds.toFixed(2)  + " €"}</td>                                               
                                            </tr>
                                            )}
                                        </tbody>
                                        </table>*/}
                                        <KeywordsTable data={dataKeywords} total={totalKeywords} />
                                    </div>
                                    </>
                                }
                            </Tab>
                        </Tabs>                   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchAds