import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import moment from 'moment';
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

import APICalls from "../api/APICalls.js";

import { AppContext } from "../context/AppProvider.js";
import { PaginationControl } from 'react-bootstrap-pagination-control';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Users = (props) => {
    const TABLE_ITEMS_LIMIT = 50;

    const { app, userData } = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const apiCalls = new APICalls(axiosPrivate, navigate, location);

    const [searchUser, setSearchUser] = useState("");

     /* Data */
     
    const [users, setUsers] = useState();
    const [page, setPage] = useState(location?.state?.page != undefined ? location.state.page : 1)

    // fetch data
    const dataFetch = async (page) => {
        apiCalls.reports().getUsers((page - 1) * TABLE_ITEMS_LIMIT, TABLE_ITEMS_LIMIT, app.bundleId, (err, data) => {
            setUsers(data.users);
        });
    };

    useEffect(() => {
        dataFetch(location?.state?.page ? location.state.page : 1);
        console.log(users)
    }, []);

    function handleUserClick(item) {
        navigate('/userDetails?id=' + item.id, {state: {page: page}, replace: false});
    }
      
    function findUser(user) {
        if (user != undefined && user != "") {
            if (!isNaN(+user)) {
                navigate('/userDetails?id=' + user, {state: {page: page}, replace: false});
            } else {
                navigate('/userDetails?email=' + user, {state: {page: page}, replace: false});
            }
        }
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <div className="row align-items-center">
                    <h4 className="col-2 mt-0 mb-0">Users</h4>
                    <h4 className="col-0 col-xxl-4 col-lg-3 col-md-2"></h4>
                    <div className="col-2 col-lg-1 col-md-2 mt-2 mt-md-0"><p className="fs-7 fw-bold mt-0 mb-0 text-start text-md-end">Search:</p></div>
                    <div className="col-10 col-xxl-4 col-lg-4 col-md-4 mt-2 mt-md-0">
                        <Form.Control type="text" id="dateFrom" className="fs-7" value={searchUser} onChange={(e) => setSearchUser(e.target.value)}></Form.Control>
                    </div>
                    <div className="col-12 col-xxl-1 col-lg-2 col-md-2 mt-2 mt-md-0">
                        <Button variant="primary" className="w-100 fs-7" onClick={(e) => findUser(searchUser)}>Find</Button>
                    </div>
                </div>
                
                <table className="table table-bordered table-hover mt-4">
                <thead>
                    <tr>
                        <th scope="col" className="fs-7 fw-bold">ID</th>
                        <th scope="col" className="fs-7 fw-bold">Email</th>
                        <th scope="col" className="fs-7 fw-bold">Has receipt</th>  
                        <th scope="col" className="fs-7 fw-bold">Install date</th>  
                        <th scope="col" className="fs-7 fw-bold">Create date</th>  
                    </tr>
                </thead>
                <tbody>
                    {users?.map(item => 
                    <tr key={item?.id} role="button" onClick={() => handleUserClick(item)}>
                        <td className="fs-7">{item?.id}</td>
                        <td className="fs-7">{item?.email != null ? item.email : "(not set)"}</td>          
                        <td className="fs-7">{item?.hasReceipt == 1 ? <span className="badge text-bg-success">Yes</span> : <span className="badge text-bg-danger">No</span>}</td>
                        <td className="fs-7">{moment(new Date(item?.installDate)).format("DD.MM.YYYY HH:mm:ss")}</td>
                        <td className="fs-7">{moment(new Date(item?.created)).format("DD.MM.YYYY HH:mm:ss")}</td>
                    </tr>
                    )}
                </tbody>
                </table>

                {/*<Pagination >
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Ellipsis/>
                    {[...Array(5)].map((x, number) =>
                        <Pagination.Item key={number} active={number === pageNumber} onClick={(event) => paginationClicked(event)} >{number}</Pagination.Item>
                    )}
                    <Pagination.Ellipsis/>
                    <Pagination.Next />
                    <Pagination.Last />
                </Pagination>
                */}

                <PaginationControl
                    page={page}
                    between={4}
                    total={10000}
                    limit={TABLE_ITEMS_LIMIT}
                    changePage={(page) => {
                        setPage(page); 
                        dataFetch(page);
                    }}
                    last={true}
                    ellipsis={1}
                />
            </div>
        </div>
    )

    /*
            /*<div className="content">
            <div className="container-fluid">
            <div className="row">
                <Card id="userCard" className="shadow col-sm-4"> 
                    <Card.Body>
                        <div className="m-1" >
                            <Image src="avatar.png" className="w-100 mb-3" rounded/>
                            <div>
                                <h1 className="fw-bold">{user?.name}</h1>
                                <p>Email: {user?.email}</p>
                                <p>Role: {user?.role}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
    */
}

export default Users