//const API_URL = "http://167.235.154.9/tatdashboard";
const API_URL = "https://datracker.devapps.si/dashboardapi";
//const API_URL = "http://localhost:3100";

const LOGIN_URL = "/api/auth/login";
const REGISTER_URL = "/api/auth/register";
const TOKEN_REFRESH_URL = "/api/auth/token/refresh";
const LOGOUT_URL = "/api/auth/logout";

const CURRENT_USER_URL = "/api/users/me";
const USERS_URL = "/api/users";
const USERS_RESET_PASSWORD_REQUEST_URL = "/api/users/resetPasswordRequest";
const USERS_RESET_PASSWORD_URL = "/api/users/passwordReset";

const NOTIFICATION_FORWARDS_URL = "/api/settings/notificationForwards";

const REPORTS_USERS_URL = "/api/reports/users";
const REPORTS_USER_INFO_URL = "/api/reports/userinfo";
const REPORTS_DASHBOARD_URL = "/api/reports/dashboard";
const REPORTS_PROPERTY_CONDITIONS_URL = "/api/reports/propertyConditions";

const APPS_URL = "/api/apps";
const APPS_AVAILABLE_URL = "/api/apps/available";
const APPS_ACTIVATE_URL = "/api/apps/activate";
const APPS_DEACTIVATE_URL = "/api/apps/deactivate";
const APPS_UPDATE_PRICES_URL = "/api/apps/updatePrices";

const PROPERTIES_LIST_URL = "/api/properties/list";
const PROPERTIES_PROPERTY_VALUES_URL = "/api/properties/propertyValues";
const PROPERTIES_PROPERY_REPORT_LIST_URL = "/api/properties/propertyReportsList";
const PROPERTIES_PROPERTY_REPORT = "/api/properties/propertyReport";

const SEARCHADS_ORGANIZATIONS_URL = "/api/searchads/organizations";
const SEARCHADS_CAMPAIGNS_URL = "/api/searchads/campaigns";
const SEARCHADS_CAMPAIGNS_REPORT_URL = "/api/searchads/campaignsReport";
const SEARCHADS_KEYWORDS_REPORT_URL = "/api/searchads/keywordsReport";

const TOOLS_TRANSACTION_HISTORY = "/api/tools/transactionHistory";
const TOOLS_VERIFY_RECEIPT = "/api/tools/verifyReceipt";

const APISettings = {
    API_URL,
    LOGIN_URL,
    REGISTER_URL,
    TOKEN_REFRESH_URL,
    LOGOUT_URL,

    CURRENT_USER_URL,
    USERS_URL,
    USERS_RESET_PASSWORD_REQUEST_URL,
    USERS_RESET_PASSWORD_URL,

    NOTIFICATION_FORWARDS_URL,

    REPORTS_USERS_URL,
    REPORTS_USER_INFO_URL,
    REPORTS_DASHBOARD_URL,
    REPORTS_PROPERTY_CONDITIONS_URL,

    APPS_URL,
    APPS_AVAILABLE_URL,
    APPS_ACTIVATE_URL,
    APPS_DEACTIVATE_URL,
    APPS_UPDATE_PRICES_URL,

    PROPERTIES_LIST_URL,
    PROPERTIES_PROPERTY_VALUES_URL,
    PROPERTIES_PROPERY_REPORT_LIST_URL,
    PROPERTIES_PROPERTY_REPORT,

    SEARCHADS_ORGANIZATIONS_URL,
    SEARCHADS_CAMPAIGNS_URL,
    SEARCHADS_CAMPAIGNS_REPORT_URL,
    SEARCHADS_KEYWORDS_REPORT_URL,

    TOOLS_TRANSACTION_HISTORY,
    TOOLS_VERIFY_RECEIPT
}

export default APISettings;