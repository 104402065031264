import React, { useContext } from "react";

import { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css"
import { useAsyncError, useLocation, useNavigate } from 'react-router-dom';

import moment from 'moment';

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown'

import { AppContext } from "../context/AppProvider.js";
import APICalls from "../api/APICalls.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

/* Charts */

import ActiveUsersChart from "../components/charts/ActiveUsersChart.js"
import SessionsChart from "../components/charts/SessionsChart.js";
import RevenueChart from "../components/charts/RevenueChart.js";
import NewUsersChart from "../components/charts/NewUsersChart.js";
import ProductUnitsChart from "../components/charts/ProductUnitsChart.js";
import ProductRevenueChart from "../components/charts/ProductRevenueChart.js";

const Dashboard = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const { app, userData } = useContext(AppContext)
    
    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    const [dateFrom, setDateFrom] = useState(moment(new Date()).add(-10, "days").format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));

    const [days, setDays] = useState(null);
    const [products, setProducts] = useState(null);

    const [filter, setFilter] = useState({});
    const [countries, setCountries] = useState([])

    var [toggle, setToggle] = useState(false);

    const [dateFilter, setDateFilter] = useState("Custom");

    const [refreshingData, setRefreshingData] = useState(true);

    function didToggle() {
        var sidebar = document.getElementsByClassName("collapse");
        sidebar[0].className = sidebar[0].className === "collapse hidden" ? "collapse show" : "collapse hidden";
    }

    // fetch data
    const dataFetch = async (from, to) => {
        let filter = {startDate: from, endDate: to, country: "All"}
        setFilter(filter);

        setRefreshingData(true);
        apiCalls.reports().dashboard(from, to, app.bundleId, (err, data) => {
            setRefreshingData(false);
            if (err) {
                console.log(err);
          
            } else {
                //Load countries filter
                var allCountries = [];
                data?.days?.map(day => {
                    day.countries?.map(item => {
                        var index = allCountries.findIndex(obj => {
                            return obj.name === item.country;
                        });
                        if (index === -1) {
                            allCountries.push({name: item.country, active: 0})
                        }
                    })
                })

                allCountries.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

                var sortedCountries = []
                sortedCountries.push({name: "All", active: 1})
                allCountries.map(country => {
                    sortedCountries.push(country)
                })
                
                setCountries(sortedCountries)
                setDays(data);

                //Find all products
                var allProducts = [];
                data?.days?.forEach(days => {
                    days.countries.forEach(country => {
                        country.inAppPurchases?.forEach(inAppPurchase => {
                            var index = allProducts.findIndex(obj => {
                                return obj.productId === inAppPurchase.productId;
                            });
                            if (index === -1) {
                                allProducts.push({productId: inAppPurchase.productId})
                            }
                        })
                    })
                })
                setProducts(allProducts);
            }
        });
    };
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    const reloadData = () => {
        dataFetch(dateFrom, dateTo)
    }

    function setShowHideCollapse(windowSize) {
        var collapse = document.getElementsByClassName("collapse");
        collapse[0].className = windowSize.innerWidth < 768 ? "collapse hidden" : "collapse show"; //Breakpoint sm
    }

    useEffect(() => {
        function handleWindowResize() {
            let newWindowSize = getWindowSize();
            setShowHideCollapse(newWindowSize);
            setWindowSize(newWindowSize);
        }
      
        setShowHideCollapse(getWindowSize());

        window.addEventListener('resize', handleWindowResize);
      
        dataFetch(dateFrom, dateTo);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function onCountryClick(country) {
        countries.map(item => {
            item.active = 0
        })

        var index = countries.findIndex(obj => {
            return obj.name === country.name;
        });
        if (index != -1) {
            countries[index].active = 1
        }
        
        setFilter({...filter, country: country.name })
    }
      
    function handleDateSelect(eventkey) {
        setDateFilter(eventkey)

        if (eventkey == "Custom") {
            return;
        }

        let dateFrom = moment()
        let dateTo = moment()

        switch(eventkey) {
            case "Today":
                dateFrom = moment()
                dateTo = moment()
                break;
            case "Yesterday":
                dateFrom = moment().subtract(1, "days")
                dateTo = moment().subtract(1, "days")
                break;
            case "Last 7 days":
                dateFrom = moment().subtract(6, "days")
                dateTo = moment()
                break;
            case "Last 30 days":
                dateFrom = moment().subtract(29, "days")
                dateTo = moment()
                break;
            case "This month":
                dateFrom = moment().startOf('month')
                dateTo = moment().endOf('month')
                break;
            case "Last month":
                dateFrom = moment().subtract(1,'month').startOf('month')
                dateTo = moment().subtract(1,'month').endOf('month')
                break;
        }
        setDateFrom(dateFrom.format("YYYY-MM-DD"))
        setDateTo(dateTo.format("YYYY-MM-DD"))

        dataFetch(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"))
    }

    function dateFromChanged(date) {
        setDateFilter("Custom");
        setDateFrom(date)
    }

    function dateToChanged(date) {
        setDateFilter("Custom");
        setDateTo(date)
    }

    return (
        <div className="content">
            <div className="container-xxl">
                <div className="chart-container">
                    <div className="d-flex align-items-center">
                        <h4 className="m-0">Dashboard</h4>
                        {refreshingData == true ?
                        <>
                            <span className="spinner-border spinner-border-md ms-3" role="status"></span>
                        </>
                        :
                        <>
                        </>
                        }
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <p className="fs-6 fw-bold">Select date</p>
                                    <div className="row col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 mt-3">
                                        <div className="col-12 col-lg-4">
                                            <Form.Control type="date" id="dateFrom" className="fs-7" value={dateFrom} onChange={(e) => dateFromChanged(e.target.value)}></Form.Control>
                                        </div>
                                        <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                                            <Form.Control type="date" id="dateTo" className="fs-7"  value={dateTo} onChange={(e) => dateToChanged(e.target.value)}></Form.Control>
                                        </div>
                                        <div className="col-12 col-lg-2 mt-2 mt-lg-0">
                                            <Button variant="primary" className="w-100 fs-7" onClick={reloadData}>
                                                Submit
                                            </Button>
                                        </div> 
                                        <div className="col-12 col-lg-2 mt-2 mt-lg-0">
                                            <Dropdown className='' onSelect={handleDateSelect}>
                                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic"className="fs-7">{dateFilter}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="Custom" title="Custom" className="fs-7">Custom</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Today" title="Today" className="fs-7">Today</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Yesterday" title="Yesterday" className="fs-7">Yesterday</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Last 7 days" title="Last 7 days" className="fs-7">Last 7 days</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Last 30 days" title="Last 30 days" className="fs-7">Last 30 days</Dropdown.Item>
                                                    <Dropdown.Item eventKey="This month" title="This month" className="fs-7">This month</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Last month" title="Last month" className="fs-7">Last month</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> 
                                        </div> 
                                    </div>

                                    <p className="fs-6 fw-bold mt-3 mb-0">Select country</p>
                                    <button className="btn btn-outline-secondary d-md-none mt-3 fs-7" onClick={() => didToggle(toggle => !toggle)}>
                                        Expand
                                    </button>
                                    <div className="collapse" id="collapseTarget">
                                        <div className="d-flex flex-wrap mt-3">
                                            {countries?.map(country => 
                                                <div className="me-1 mt-1" style={{width: 52+"px"}}>
                                                    {country.active == 1 ? <button type="button" className="btn btn-primary w-100 fs-7" onClick={() => onCountryClick(country)}>{country.name}</button> : <button type="button" className="btn btn-outline-secondary w-100 fs-7" onClick={() => onCountryClick(country)}>{country.name}</button>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-12 col-xl-12 mt-3">
                            <RevenueChart data={days} filter={filter} />
                        </div>
                        <div className="col-12 col-xl-6 mt-3">
                            <NewUsersChart data={days} filter={filter}/>
                        </div>
                        <div className="col-12 col-xl-6 mt-3">
                            <ActiveUsersChart data={days} filter={filter}/>
                        </div>
                        <div className="col-12 col-xl-6 mt-3">
                            <SessionsChart data={days} filter={filter}/>
                        </div>

                        {products?.map(product => 
                            <div className="col-12 col-xl-12 mt-3">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">{product.productId}</h5></div>  
                                        </div>
                                    </div> 
                                    <div className="card-body">
                                        <div className="row ps-2 pe-2">
                                            <div className="col-12 col-xl-6">
                                                <ProductUnitsChart data={days} filter={filter} productId={product.productId}/>
                                            </div>
                                            <div className="col-12 col-xl-6 mt-3 mt-xl-0">
                                                <ProductRevenueChart data={days} filter={filter} productId={product.productId}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard