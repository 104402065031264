import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import React, { useState } from 'react';

import useAuth from "./hooks/useAuth";

/* Theme variables */
import './theme/variables.css';

/* Components */
import Layout from './components/Layout';
import PersistLogin from "./components/PersistLogin";
import HomeLayout from "./components/HomeLayout";
import RequireAuth from './components/RequireAuth';
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";

/* Pages */
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import AppSettings from './pages/AppSettings'
import Settings from './pages/Settings'
import Properties from './pages/Properties'
import Admin from "./pages/Admin";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import SearchAds from "./pages/SearchAds";
import PasswordRequest from "./pages/PasswordRequest";
import PasswordReset from "./pages/PasswordReset";
import Tools from "./pages/Tools";

export const ROLES = {
  'Viewer': 0 ,
  'Admin': 1  
}

function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/passwordRequest" element={<PasswordRequest />} />
            <Route path="/passwordReset" element={<PasswordReset />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* protected routes */}
            <Route element={<PersistLogin />}>
              <Route element={<HomeLayout />}>
                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/users" element={<Users />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Admin}/>}>
                  <Route path="/admin" element={<Admin />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/appSettings" element={<AppSettings />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/userDetails" element={<UserDetails />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/tools" element={<Tools />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/settings" element={<Settings />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/properties" element={<Properties />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ROLES.Viewer}/>}>
                  <Route path="/searchAds" element={<SearchAds />} />
                </Route>

              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing />} />

          </Route>
        </Routes>
    </>
  );
}

export default App;