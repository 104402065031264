import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { ArrowDown, ArrowUp, X } from "react-bootstrap-icons";
import { Button } from 'react-bootstrap';

function MultiSelectDropdown({name, options, filter, onSelectedChange}) {
  //const [selectedOptions, setSelectedOptions] = useState(filter);

  //console.log(selectedOptions)
  //console.log(filter)
  const handleOptionChange = (event) => {
    var index = options.findIndex(obj => obj.id === parseInt(event.target.value));
    var selectedOptionsIndex = filter.findIndex(obj => obj.id === options[index].id);
    var selOptions = [ ...filter ];
    if (selectedOptionsIndex == -1) {
        selOptions.push(options[index])
    } else {
        selOptions.splice(selectedOptionsIndex, 1)
    }

    onSelectedChange(selOptions)
    //setSelectedOptions(selOptions)
  };

  function getSelectedOptions() {
    var retString = ""
    var index = 0
    filter?.forEach((item) => {
        retString += (index > 0 ? ", " : "") + item.name 
        index++
    })

    return retString
  }

  function clearClicked() {
    var selOptions = [ ...filter ];
    selOptions = []
    onSelectedChange(selOptions)
    //setSelectedOptions(selOptions)
  }

  const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    const [sortAsc, setSortAsc] = useState(true);
    
    return (
        <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        >
            <div className="container m-0 p-0 mx-3 my-2 w-auto">
                <div className="d-flex">
                    <div className="flex-grow-1 pe-3">
                        <Form.Control className="fs-7" placeholder="Type to filter..." value={value} onChange={(e) => setValue(e.target.value)}></Form.Control>
                    </div>
                    <div className="p-0 m-0">
                        <button type="button" className="btn h-100 p-0 m-0" onClick={(e) => setSortAsc(!sortAsc)}>{sortAsc ? <ArrowUp size={20}/> : <ArrowDown size={20}/>}</button>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled">
                {React.Children.toArray(children).sort((a, b) => (sortAsc ? a.props.children.localeCompare(b.props.children) : b.props.children.localeCompare(a.props.children))).filter(
                (child) =>
                    !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                )}
            </ul>
            </div>
        );},
    );

    function isSelected(option) {
        var index = options.findIndex(obj => obj.id === option.id);
        var selectedOptionsIndex = filter.findIndex(obj => obj.id === options[index].id);
        if (selectedOptionsIndex == -1) {
            return false
        } else {
            return true
        }
    }

  return (
    <div className="d-flex flex-row align-items-center">
        <Dropdown className="property_dropdown"> 
            <Dropdown.Toggle variant="" id="dropdown-basic" className={filter?.length == 0 ? "dropdown-toggle-no-carret fs-7 text-primary" : "dropdown-toggle-no-carret fs-7"} >
                {filter.length <= 0 ? "+ Add " + name  : "Selected " + name + ": " + getSelectedOptions() }
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu">
                {options?.map(option => 
                    <Form.Check                           
                        className="fs-7 ms-2 me-2"
                        key={option.id}
                        type="checkbox"
                        id={`option_${option.id}`}
                        label={option.name}
                        onChange={handleOptionChange}
                        value={option.id}
                        checked={isSelected(option)}
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
        {filter.length > 0 ?
            <Button className='fs-7 btn-light text-primary' onClick={() => clearClicked()}><X></X>Clear</Button>
        :
            <></>
        }
    </div>
    
  );
};

export default MultiSelectDropdown;