import React from "react";
import moment from 'moment';
import LineChart from "./LineChart";

function SessionsChart({data, filter}) {
    //Init records 
    let records = []
    var total = 0

    //Init all records with date
    var startDate = moment(filter.startDate);
    var endDate = moment(filter.endDate);
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        records.push({date: m.format("YYYY-MM-DD"), sessions: 0})
    }

    //Add all active users data
    data?.days?.forEach(item => {
        var date = item.date
        var index = records.findIndex(obj => {
            return obj.date === date;
        });
       
        if (index >= 0) {
            records[index] = {date: date, sessions: item.sessions}
            total += item.sessions
        }
    });

    //Get all charts labels and data
    let chartData = {
        labels: records?.map(item => moment(item.date).format("DD.MM.YY")),
        datasets: [{
            label: "Sessions",
            data: records?.map(item => item.sessions)
        }]
    }

    const options = {
        /*responsive: true,
        maintainAspectRatio: true,*/
        interaction: {
            intersect: false,
            mode: 'index',
        }
    }

    const plugins = [{
        afterDraw: chart => {
            if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
                ctx.stroke();
                ctx.restore(); 
            }
        }
    }]

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Sessions</h5></div>  
                    <div className="col-6 d-flex align-items-center justify-content-end"><h6 className="mb-0">Total: {total}</h6></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart chartData={chartData} className={"medium-chart-container"}/>
            </div>
        </div>
    )
}

export default SessionsChart