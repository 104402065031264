import React from "react";
import moment from 'moment';
import LineChart from "./LineChart";
import { Colors } from 'chart.js';
import {Chart as ChartJS} from "chart.js/auto"

function ProductUnitsChart({data, filter, productId}) {
    //Init records 
    let records = []

    var totalTrials = 0
    var totalRenewals = 0
    var totalRefunds = 0
    var totalConversions = 0

    //Init all records with date
    var startDate = moment(filter.startDate);
    var endDate = moment(filter.endDate);
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        records.push({date: m.format("YYYY-MM-DD"), trials: 0, renewals: 0, refunds: 0, conversions: 0})
    }

    //Add all active users data
    data?.days?.forEach(days => {
        var date = days.date
        var index = records.findIndex(obj => {
            return obj.date === date;
        });
           
        if (index >= 0) {
            var trials = 0
            var renewals = 0
            var refunds = 0
            var conversions = 0

            let filteredCountries = days.countries
            if (filter.country != "All") {
                filteredCountries = days.countries?.filter(obj => obj.country === filter.country)
            }

            filteredCountries.forEach(country => {
                let filteredInAppPurchase = country.inAppPurchases;
                if(productId != undefined) {
                    filteredInAppPurchase = country.inAppPurchases?.filter(obj => obj.productId === productId)
                }

                filteredInAppPurchase?.forEach(inAppPurchase => {
                    inAppPurchase.userTypes?.forEach(userType => {
                        var tempTrials = userType.trials;
                        var tempRenewals = userType.renewals;
                        var tempRefunds = userType.refunds;
                        var tempConversions = userType.conversions;

                        trials += tempTrials
                        renewals += tempRenewals
                        refunds += tempRefunds
                        conversions += tempConversions

                        totalTrials += tempTrials
                        totalRenewals += tempRenewals
                        totalRefunds += tempRefunds
                        totalConversions += tempConversions
                    })
                })
            })
            records[index] = {date: date, trials: trials, renewals: renewals, refunds: refunds, conversions: conversions}
        }  
    });

    //Get all charts labels and data
    let chartData = {
        labels: records?.map(item => moment(item.date).format("DD.MM.YY")),
        datasets: [
            {
                label: ["Trials"],
                data: records?.map(item => item.trials),
            },
            {
                label: ["Renewals"],
                data: records?.map(item => item.renewals)
            },
            {
                label: ["Refunds"],
                data: records?.map(item => item.refunds)
            },
            {
                label: ["Conversions"],
                data: records?.map(item => item.conversions)
            }
        ]
    }
    
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Units</h5></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart  chartData={chartData} className={"medium-chart-container"}/>
            </div>
            <div className="card-footer">
                <div className="d-flex flex-wrap m-0">
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Trials: <p className="fw-bold m-0">{totalTrials}</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Conversions: <p className="fw-bold m-0">{totalConversions}</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Renewals: <p className="fw-bold m-0">{totalRenewals}</p></p></div>
                    <div className="col-6 col-xl-3 text-center"><p className="fw-normal m-0 fs-7">Refunds: <p className="fw-bold m-0">{totalRefunds}</p></p></div>
                </div>
            </div>
    </div>
    )
}

export default ProductUnitsChart