import axios from "axios";
import APISettings from "./APISettings";

const instance = axios.create({
    baseURL: APISettings.API_URL,
    headers: {
      "Content-Type": "application/json",
    }
  });

const register = (email, password, name, role) => {
  return instance.post(APISettings.REGISTER_URL, {
    email,
    password,
    name,
    role
  });
};

const login = (email, password) => {
  return instance
    .post(APISettings.LOGIN_URL, {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  //TODO: clear auth data

  return instance.post(APISettings.LOGOUT_URL).then((response) => {
    return response.data;
  });
};

const APIAuth = {
  register,
  login,
  logout,
}

export default APIAuth;