import React, { useContext } from "react";

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APICalls from "../api/APICalls.js";
import { AppContext } from "../context/AppProvider.js";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown'

import { ROLES } from "../App.js";



import JSONPretty from "react-json-pretty";
require("../assets/custom.css")

//var JSONPrettyTheme = require('react-json-pretty/dist/1337');

const Tools = (props) => {
    const {apps, app, setApps, setApp } = useContext(AppContext)
    const { userData, setUserData } = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    const [responseTransaction, setResponseTransaction] = useState("");
    const [transactionId, setTransactionId] = useState("");

    const [responseReceipt, setResponseReceipt] = useState("");
    const [receipt, setReceipt] = useState("");

    useEffect(() => {
        
    }, []);

    function getTransactionHistory(transactionId) {
        if (transactionId != null && transactionId != "") {
            apiCalls.tools().transactionHistory(app.bundleId, transactionId, (err, data) => {
                console.log(data)
                if (err) {
                    setResponseTransaction(err)
                } else {
                    setResponseTransaction(data)
                }
            })
        }
    }

    function verifyReceipt(receipt) {
        if (receipt != null && receipt != "") {
            apiCalls.tools().verifyReceipt(receipt, (err, data) => {
                console.log(data)
                if (err) {
                    setResponseReceipt(err)
                } else {
                    setResponseReceipt(data)
                }
            })
        }
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <h4>Tools</h4>

                <div className="card mt-5">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Transaction History</h5></div>  
                        </div>
                    </div> 
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className=""><p className="fs-7 fw-bold m-0">Transaction ID:</p></div>
                            <div className="ms-3">
                                <Form.Control type="text" id="dateFrom" className="fs-7" value={transactionId} onChange={(e) => setTransactionId(e.target.value)}></Form.Control>
                            </div>
                            <div className="ms-3">
                                <Button variant="primary" className="w-100 fs-7" onClick={(e) => getTransactionHistory(transactionId)}>Get</Button>
                            </div>
                        </div>
                        {/*<div className="row align-items-center">
                            <div className="col-3 col-lg-2 col-md-3 mt-2 mt-md-0"><p className="fs-7 fw-bold mt-0 mb-0 text-start">Transaction ID:</p></div>
                            <div className="col-10 col-xxl-4 col-lg-4 col-md-4 mt-2 mt-md-0">
                                <Form.Control type="text" id="dateFrom" className="fs-7" value={transactionId} onChange={(e) => setTransactionId(e.target.value)}></Form.Control>
                            </div>
                            <div className="col-12 col-xxl-1 col-lg-2 col-md-2 mt-2 mt-md-0">
                                <Button variant="primary" className="w-100 fs-7" onClick={(e) => getTransactionHistory(transactionId)}>Get</Button>
                            </div>
                        </div>*/}
                        <JSONPretty  data={responseTransaction} className="style-json-pretty mt-3 fw-bold" themeClassName="custom-json-pretty"></JSONPretty>
                    </div>
                </div>

                <div className="card mt-5">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Verify Receipt</h5></div>  
                        </div>
                    </div> 
                    <div className="card-body">
                    <div className="d-flex align-items-top">
                            <div className=""><p className="fs-7 fw-bold mt-2 mb-0 text-start">Receipt:</p></div>
                            <div className="ms-3 col-7 col-xxl-4 col-lg-6 col-md-8 mt-2" style={{maxWidth: 500+"px"}}>
                                <textarea className="form-control fs-7" rows={3} type="text" id="dateFrom" value={receipt} onChange={(e) => setReceipt(e.target.value)}></textarea>
                            </div>
                            <div className="ms-3 mt-2">
                                <Button variant="primary" className="w-100 fs-7" onClick={(e) => verifyReceipt(receipt)}>Get</Button>
                            </div>
                        </div>
                        {/*<div className="row align-items-top">
                            <div className="col-2 col-lg-2 col-md-3 mt-2 mt-md-0"><p className="fs-7 fw-bold mt-0 mb-0 text-start">Receipt:</p></div>
                            <div className="col-10 col-xxl-4 col-lg-4 col-md-4 mt-2 mt-md-0">
                                <textarea className="form-control fs-7" rows={3} type="text" id="dateFrom" value={receipt} onChange={(e) => setReceipt(e.target.value)}></textarea>
                            </div>
                            <div className="col-12 col-xxl-1 col-lg-2 col-md-2 mt-2 mt-md-0">
                                <Button variant="primary" className="w-100 fs-7" onClick={(e) => verifyReceipt(receipt)}>Get</Button>
                            </div>
                        </div>*/}
                        <JSONPretty  data={responseReceipt} className="style-json-pretty mt-3 fw-bold" themeClassName="custom-json-pretty"></JSONPretty>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tools