import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom"
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar";

import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

import { AppContext } from "../context/AppProvider";
import APICalls from '../api/APICalls';

const HomeLayout = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [apps, setApps] = React.useState([]);
    const [app, setApp] = React.useState(false);
    const [userData, setUserData] = React.useState(false);

    const apiCalls = new APICalls(axiosPrivate, navigate, location);

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            apiCalls.users().me((err, data) => {
                setUserData(data);
            });
        };
        dataFetch();
    }, []);

    return (
        <AppContext.Provider value={{apps, setApps, app, setApp, userData, setUserData}}>
            <Topbar />
            <Sidebar /> 
            <div className="wrapper" key={app.bundleId}>   
                <div className="main d-flex flex-row w-100 h-100">
                    <Outlet />    
                </div> 
            </div>
        </AppContext.Provider>
    )

    /*return (
        <AppContext.Provider value={{apps, setApps, app, setApp, userData, setUserData}}>
            <div className="wrapper" key={app.bundleId}>   
                <Topbar />
                <div className="main d-flex flex-row w-100 min-vh-100">
                    <Sidebar /> 
                    <Outlet />    
                </div> 
            </div>
        </AppContext.Provider>
    )*/
}

export default HomeLayout