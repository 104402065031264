import React from "react";
import moment from 'moment';
import LineChart from "./LineChart";

function ActiveUsersChart({data, filter}) {
    //Init records 
    let records = []
    var total = 0

    //Init all records with date
    var startDate = moment(filter.startDate);
    var endDate = moment(filter.endDate);
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        records.push({date: m.format("YYYY-MM-DD"), activeUsers: 0})
    }

    //Add all active users data
    data?.days?.forEach(item => {
        var date = item.date
        var index = records.findIndex(obj => {
            return obj.date === date;
        });
       
        if (index >= 0) {
            records[index] = {date: date, activeUsers: item.activeUsers}
            total += item.activeUsers
        }
    });

    //Get all charts labels and data
    let chartData = {
        labels: records?.map(item => moment(item.date).format("DD.MM.YY")),
        datasets: [{
            label: "Active users",
            data: records?.map(item => item.activeUsers)
        }]
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start"><h5 className="mb-0">Active Users</h5></div>  
                </div>
            </div> 
            <div className="card-body">
                <LineChart chartData={chartData} className={"medium-chart-container"}/>
            </div>
        </div>
    )
}

export default ActiveUsersChart