import React, { useContext } from "react";

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APICalls from "../api/APICalls.js";
import { AppContext } from "../context/AppProvider.js";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown'

import { AlignMiddle, ArrowDown, ArrowUp, TrashFill } from "react-bootstrap-icons";

const Properties = (props) => {
    const {apps, app, setApps, setApp } = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    /* Data */
    const [addNewEnabled, setAddNewEnabled] = useState(true);

    const [conditionName, setConditionName] = useState("");

    const [search, setSearch] = useState("");
    const [total, setTotal] = useState({devices: 0, transactions: 0, earnings: 0, perDevice: 0});
    const [report, setReport] = useState();
    const [reportLoading, setReportLoading] = useState(false);

    const [predefinedConditions, setPredefinedConditions] = useState([]);
    const [selectedCondition, setSelectedCondition] = useState(null);

    //const [sortAsc, setSortAsc] = useState(true);

    const [properties, setProperties] = useState(null);
    const [propertyConditions, setPropertyConditions] = useState([]);
    const [selectedGroupBy, setSelectedGroupBy] = useState("-");

    const fetchPredefinedConditions = async () => {
        apiCalls.properties().propertyReportsList(app.bundleId, (err, data) => {
            setPredefinedConditions(data?.reports ?? [])
        })
    };

    const dataFetch = async () => {
        apiCalls.properties().list(app.bundleId, (err, data) => {
            setProperties(data?.properties)
        })
    };
    


    useEffect(() => {
        dataFetch();
        fetchPredefinedConditions()
    }, []);

    function handlePropertySelect(eventkey, id) {
        const conditions = [ ...propertyConditions ];
        var index = conditions.findIndex(obj => obj.id === id);
        conditions[index].propertyValuesLoading = true;
        conditions[index].selectedProperty = eventkey
        conditions[index].selectedPropertyValue = null
        setPropertyConditions(conditions)
        checkAddNewButton(conditions)

        apiCalls.properties().propertyValues(app.bundleId, eventkey, (err, data) => {
            const conditions = [ ...propertyConditions ];
            var filtered = data?.values.filter(function (el) {
                return el.value != null;
            });
            var index = conditions.findIndex(obj => obj.id === id);
            conditions[index].propertyValuesLoading = false;
            conditions[index].propertyValues = filtered
            conditions[index].selectedPropertyValue = null
            setPropertyConditions(conditions)
            setSearch("")
            //setSortAsc(true)
            checkAddNewButton(conditions)
        })
    }

    function handleOperatorSelect(eventkey, id) {
        const conditions = [ ...propertyConditions ];
        var index = conditions.findIndex(obj => obj.id === id);
        conditions[index].selectedOperator = eventkey
        setPropertyConditions(conditions)
    }

    function handlePropertyValueSelect(eventkey, id) {
        const conditions = [ ...propertyConditions ];
        var index = conditions.findIndex(obj => obj.id === id);
        conditions[index].selectedPropertyValue = eventkey
        setPropertyConditions(conditions)

        checkAddNewButton(conditions)
    }

    function handleGroupBySelect(eventkey) {
        setSelectedGroupBy(eventkey)
    }

    function handleDelete(id) {
        const conditions = [ ...propertyConditions ];
        var index = conditions.findIndex(obj => obj.id === id);

        conditions.splice(index, 1)
        setPropertyConditions(conditions)

        checkAddNewButton(conditions)
    }

    function addClicked() {
        const conditions = [ ...propertyConditions ];
        
        //Get highest id
        var highestId = 0
        conditions.forEach(item => {
            highestId = Math.max(item.id, highestId)
        });

        conditions.push({id: highestId + 1, properties: properties, selectedProperty: null, propertyValues: null, propertyValuesLoading: false, selectedPropertyValue: null, selectedOperator: "="})
        setPropertyConditions(conditions)

        checkAddNewButton(conditions)
    }

    /* Predefined conditions */

    function handlePredefinedConditionSelect(eventKey) {
        var index = predefinedConditions.findIndex(obj => obj.id == eventKey);
        if (index == -1) {
            setSelectedCondition(null)
            setPropertyConditions([])
            setSelectedGroupBy("-")
            return
        } else {
            setSelectedCondition(predefinedConditions[index].name)
        }

       apiCalls.properties().getPropertyReport(eventKey, (err, data) => {
            if(err) {
                //TODO: not found show error
            } else {
                //Set group by
                setSelectedGroupBy(data.groupBy)

                //Set conditions
                const conditions = [];


                async function processItem(id, item) {
                    // Simulate a delay of 1 second using setTimeout and a Promise
                    return new Promise((resolve) => {
                        conditions.push({id: id, properties: properties, selectedProperty: item.property, propertyValues: null, propertyValuesLoading: true, selectedPropertyValue: item.value, selectedOperator: item.condition})

                        apiCalls.properties().propertyValues(app.bundleId, item.property, (err, data) => {
                            var index = conditions.findIndex(obj => obj.id === id);
                            conditions[index].propertyValuesLoading = false;
                            conditions[index].propertyValues = data?.values
                            resolve();
                        })
                    });
                }
                  
                async function processArray(array) {
                    if (array == undefined || array == null || array.length == 0) {
                        return
                    }

                    let id = 0
                    for (let i = 0; i < array.length; i++) {
                        await processItem(id, array[i]);
                        id++
                    }
                    setPropertyConditions(conditions)
                }

                processArray(data?.conditions)
                checkAddNewButton(conditions)
            }
        })
    }

    function getPredefinedConditionSaveClicked() {
        let data = {}
        data.appBundleId = app.bundleId
        data.name = conditionName
        data.groupBy = selectedGroupBy
        data.conditions = []
        
        propertyConditions.forEach(item => {
            let condition = {}

            condition.property = item.selectedProperty
            condition.condition = item.selectedOperator
            condition.value = item.selectedPropertyValue

            data.conditions.push(condition)
        });
        
        apiCalls.properties().savePropertyReport(data, (err, data) => {
            fetchPredefinedConditions()
            setSelectedCondition(data.name)
        })
    }

    function getPredefinedConditionUpdateClicked() {
        var index = predefinedConditions.findIndex(obj => obj.name == selectedCondition);

        let data = {}
        data.appBundleId = app.bundleId
        data.name = predefinedConditions[index].name
        data.groupBy = selectedGroupBy
        data.conditions = []
        
        propertyConditions.forEach(item => {
            let condition = {}

            condition.property = item.selectedProperty
            condition.condition = item.selectedOperator
            condition.value = item.selectedPropertyValue

            data.conditions.push(condition)
        });
        

        apiCalls.properties().updatePropertyReport(predefinedConditions[index].id, data, (err, data) => {
            setConditionName("")
            fetchPredefinedConditions()
        })
    }

    function getPredefinedConditionDeleteClicked() {
        var index = predefinedConditions.findIndex(obj => obj.name == selectedCondition);

        apiCalls.properties().deletePropertyReport(predefinedConditions[index].id, (err, data) => {
            setSelectedCondition(null)

            fetchPredefinedConditions()
        })
    }

    function getReportClicked() {

        var conditions = []
        propertyConditions?.forEach(item => {
            if (item.selectedProperty != null && item.selectedPropertyValue != null) {
                conditions.push({key: item.selectedProperty, value: item.selectedPropertyValue, condition: item.selectedOperator})
            }
        });
        var group = selectedGroupBy != "-" ? selectedGroupBy : null
        if ((conditions?.length > 0 || group != null)) {
            setReportLoading(true);
        
            apiCalls.reports().propertyConditions(conditions?.length > 0 ? conditions : null, group, app.bundleId, (err, data) => {
                var totalDevices = 0
                var totalTransactions = 0
                var totalEarnings = 0
                var totalPerDevice = 0

                data?.report.forEach(item => {
                    totalDevices += item.devices
                    totalTransactions += item.transactions
                    totalEarnings += item.estimatedEarnings
                    totalPerDevice += (item?.estimatedEarnings / item?.devices)
                });

                setTotal({devices: totalDevices, transactions: totalTransactions, earnings: totalEarnings.toFixed(2), perDevice: totalPerDevice.toFixed(2)})
                setReport(data?.report)
                setReportLoading(false);
            })
        }
    }

    function checkAddNewButton(array) {
        var enabled = true
        array?.forEach(item => {
            if (item.selectedPropertyValue == null || item.selectedProperty == null) {
                enabled = false
            }
        })
        if (enabled != addNewEnabled) {
            setAddNewEnabled(enabled)
        }
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        const [sortAsc, setSortAsc] = useState(true);
        
        return (
            <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
            >
            <div className="container m-0 p-0 mx-3 my-2 w-auto">
                <div className="d-flex">
                    <div className="flex-grow-1 pe-3">
                        <Form.Control className="fs-7" placeholder="Type to filter..." key="name" value={value} onChange={(e) => setValue(e.target.value)}></Form.Control>
                    </div>
                    <div className="p-0 m-0">
                        <button type="button" className="btn h-100 p-0 m-0" onClick={(e) => setSortAsc(!sortAsc)}>{sortAsc ? <ArrowUp size={20}/> : <ArrowDown size={20}/>}</button>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled">
                {React.Children.toArray(children).sort((a, b) => (sortAsc ? a.props.children.localeCompare(b.props.children) : b.props.children.localeCompare(a.props.children))).filter(
                (child) =>
                    !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                )}
            </ul>
            </div>
        );
        },
    );

    return (
        <div className="content" >
            <div className="container-fluid">
                <h4>Properties</h4>
                <div className="row">
                    <div className=""> 
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="">
                                        <div className="d-flex flex-row mb-3 align-items-center">
                                            <p className="fs-6 fw-bold m-0 p-0">Conditions</p>
        
                                        </div>

                                        {propertyConditions?.map(condition => 
                                            <div className="d-flex flex-row mt-2 align-items-center">
                                                <div className="me-2">
                                                    <Dropdown className="property_dropdown">
                                                        <Dropdown.Toggle variant="outline-secondary" className="fs-7">
                                                        {condition.selectedProperty == null ? "Select" : condition.selectedProperty}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="menu  p-0">
                                                        {condition.properties?.map(item => 
                                                            <Dropdown.Item eventKey={item.key} key={item.key} title={item.key} className="fs-7" onClick={(e) => handlePropertySelect(item.key, condition.id)}>{item.key}</Dropdown.Item>
                                                        )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div> 

                                                <div className="me-2">
                                                    <Dropdown className="condition_dropdown">
                                                        <Dropdown.Toggle variant="outline-secondary" className="fs-7">{condition.selectedOperator}</Dropdown.Toggle>
                                                        <Dropdown.Menu className="menu p-0">
                                                            <Dropdown.Item eventKey="=" key="=" title="=" className="fs-7" onClick={(e) => handleOperatorSelect("=", condition.id)}>=</Dropdown.Item>
                                                            <Dropdown.Item eventKey=">" key=">" title=">" className="fs-7" onClick={(e) => handleOperatorSelect(">", condition.id)}>&gt;</Dropdown.Item>
                                                            <Dropdown.Item eventKey="<" key="<" title="<" className="fs-7" onClick={(e) => handleOperatorSelect("<", condition.id)}>&lt;</Dropdown.Item>
                                                            <Dropdown.Item eventKey=">=" key=">=" title=">=" className="fs-7" onClick={(e) => handleOperatorSelect(">=", condition.id)}>&ge;</Dropdown.Item>
                                                            <Dropdown.Item eventKey="<=" key="<=" title="<=" className="fs-7" onClick={(e) => handleOperatorSelect("<=", condition.id)}>&le;</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                        </Dropdown>
                                                </div> 
                                                <div className="me-2">
                                                    <Dropdown className="property_dropdown">
                                                        <Dropdown.Toggle  variant="outline-secondary" className={condition.propertyValuesLoading == true || condition.propertyValues == null ? "fs-7 disabled" : "fs-7"}>
                                                        {condition.propertyValuesLoading == true ? 
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                                                Loading
                                                            </> 
                                                            : 
                                                            <>
                                                                {condition.selectedPropertyValue == null ? "Select" : condition.selectedPropertyValue}
                                                            </>
                                                        }
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="menu" as={CustomMenu}> 
                                                        
                                                        {condition?.propertyValues?.map(item => 
                                                            <Dropdown.Item eventKey={item.value} key={item.value} title={item.value} className="fs-7" onClick={(e) => handlePropertyValueSelect(item.value, condition.id)}>{item.value}</Dropdown.Item>
                                                        )}
                                                        </Dropdown.Menu>
                                                        </Dropdown> 
                                                </div> 
                                                <div className="me-2">
                                                    <button type="button" className="btn btn-danger" onClick={() => handleDelete(condition.id)}><TrashFill color="white" /></button>
                                                </div>
                                            </div>
                                        )}

                                        <button type="button" className={properties != null && addNewEnabled == true ? "btn btn-primary mt-2 fs-7" : "btn btn-primary mt-2 fs-7 disabled"} onClick={() => addClicked()}>
                                            {properties == null ? 
                                                <>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                                    Loading
                                                </> 
                                                : 
                                                <>
                                                    Add
                                                </>
                                            }   
                                        </button>

                                        <p className="mb-3 mt-4 fs-6 fw-bold">Group by</p>

                                        <div className="me-2">
                                            <Dropdown className="property_dropdown" onSelect={handleGroupBySelect}>
                                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className={properties == null ? "fs-7 disabled" : "fs-7"}>
                                                {selectedGroupBy == null ? "Select" : selectedGroupBy}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="menu p-0">
                                                <Dropdown.Item eventKey="-" title="-" className="fs-7">-</Dropdown.Item>
                                                {properties?.map(item => 
                                                    <Dropdown.Item eventKey={item.key} key={item.key} title={item.key} className="fs-7">{item.key}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                            </Dropdown> 
                                        </div> 

                                        <button type="button" className={reportLoading == false ? "btn btn-primary mt-3 fs-7" : "btn btn-primary mt-3 fs-7 disabled"} onClick={() => getReportClicked()}>Get report</button>
                                    </div>
                                    <div className="flex-grow-1"></div>
                                    <div className="card" style={{minWidth:200+"px"}}>
                                        <div className="card-body">
                                            {/*<p className="mb-3 fs-6 fw-bold">Predefined</p>*/}

                                            <Dropdown className="property_dropdown" onSelect={handlePredefinedConditionSelect}>
                                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className={properties == null ? "fs-7 disabled w-100" : "fs-7 w-100"}>
                                                    {selectedCondition == null ? "- Add New -" : selectedCondition}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="menu p-0">
                                                    <Dropdown.Item eventKey="-" title="New" className="fs-7">- Add New -</Dropdown.Item>
                                                    {predefinedConditions?.map(item => 
                                                        <Dropdown.Item eventKey={item.id} key={item.id} title={item.name} className="fs-7">{item.name}</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown> 

                                            {selectedCondition == null ? 
                                                <>
                                                    <Form.Control type="text" id="dateFrom" className="fs-7 mt-2" placeholder="Enter name" value={conditionName} onChange={(e) => setConditionName(e.target.value)}></Form.Control>
                                                </>
                                                :
                                                <>
                                                </>
                                            }
                                            <div className="d-flex flex-column">
                                                {selectedCondition == null ? 
                                                    <>
                                                        <button type="button" className="btn btn-primary mt-2 w-100 fs-7" onClick={() => getPredefinedConditionSaveClicked()}>Save</button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-primary mt-2 w-100 me-2 fs-7" onClick={() => getPredefinedConditionUpdateClicked()}>Update</button>                    
                                                        <button type="button" className="btn btn-danger mt-2 w-100 fs-7" onClick={() => getPredefinedConditionDeleteClicked()}>Delete</button>
                                                    </>
                                                }
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="card-body">
                        <p className="mb-3 fs-6 fw-bold">Results</p>
                        {reportLoading == true ? 
                            <>
                                <div className="d-flex justify-content-center align-items-center fs-7">
                                    <span className="spinner-border spinner-border-sm me-3" role="status"></span>
                                    Loading
                                </div>
                            </> 
                            : 
                            <>
                                <table className="table table-bordered mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col" className="bg-header-table fs-7 fw-bold">Value</th>
                                        <th scope="col" className="bg-header-table fs-7 fw-bold">Devices</th>
                                        <th scope="col" className="bg-header-table fs-7 fw-bold">Transactions</th>
                                        <th scope="col" className="bg-header-table fs-7 fw-bold">Estimated Earnings</th>
                                        <th scope="col" className="bg-header-table fs-7 fw-bold">€ / Device</th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">Total</th>
                                        <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total.devices}</th>
                                        <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total.transactions}</th>
                                        <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total.earnings}</th>
                                        <th scope="col" className="bg-header-table2 fs-7 fw-bold table-col">{total.perDevice}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report?.map(item => 
                                    <tr key={item.p_value}> 
                                        <td className="fs-7 align-middle">{item?.p_value}</td>
                                        <td className="fs-7 align-middle">{item?.devices}</td>
                                        <td className="fs-7 align-middle">{item?.transactions}</td>     
                                        <td className="fs-7 align-middle">{item?.estimatedEarnings.toFixed(2) + " €"}</td>    
                                        <td className="fs-7 align-middle">{(item?.estimatedEarnings / item?.devices).toFixed(2) + " €"}</td>  
                                    </tr>
                                    )}
                                </tbody>
                                </table>
                            </>
                        }
                    </div>
                </div>
                    {reportLoading == true ? 
                        <>
                            <div className="d-flex justify-content-center align-items-center fs-7">
                                <span className="spinner-border spinner-border-sm me-3" role="status"></span>
                                Loading
                            </div>
                        </> 
                        : 
                        <div className="mb-3">
                            {report?.map(item => 
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <div className="mb-1">
                                            <h6 className="fw-bold mt-1">{item.p_value.toUpperCase()}</h6>
                                            
                                            <div className="d-flex flex-column">
                                                <div className="d-flex mt-3">
                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.users}</p>
                                                        <span className="badge bg-primary align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Users</span>
                                                    </div>

                                                    <div className="d-flex flex-column ms-3 me-3 justify-content-center">
                                                        <p className="fs-7 mt-2 m-0 fw-bold text-center">{(item.users != 0 ? (item.trials / item.users * 100) : 0).toFixed(2) + " %"}</p>
                                                        <span className="arrow-right"></span>
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.trials}</p>
                                                        <span className="badge bg-warning align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Trials</span>
                                                    </div>

                                                    <div className="d-flex flex-column ms-3 me-3 justify-content-center">
                                                    <p className="fs-7 mt-2 m-0 fw-bold text-center">{(item.trials != 0 ? (item.conversions / item.trials * 100) : 0).toFixed(2) + "% (" + (item.users ? (item.conversions / item.users * 100) : 0).toFixed(2) + "%)"}</p>
                                                        <span className="arrow-right"></span>
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.conversions}</p>
                                                        <span className="badge bg-success align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Conversions</span>
                                                    </div>

                                                    <div className="d-flex flex-column ms-3 me-3 justify-content-center">
                                                        <span className="arrow-right" style={{top:14+"px"}}></span>
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.renewals}</p>
                                                        <span className="badge bg-info align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Renewals</span>
                                                    </div>
                                                </div>  

                                                <div className="d-flex mt-3">
                                                    <div className="d-flex flex-column ms-5 justify-content-center">
                                                        <span className="arrow-top"></span>
                                                    </div>

                                                    <div className="d-flex flex-column me-3 justify-content-center">
                                                        <p className="fs-7 mt-2 m-0 fw-bold text-center">{(item.users ? (item.purchases / item.users * 100) : 0).toFixed(2) + "%"}</p>
                                                        <span className="arrow-right2"></span>
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.purchases}</p>
                                                        <span className="badge bg-success align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Purchases</span>
                                                    </div>

                                                    <div className="d-flex flex-column ms-3 me-3 justify-content-center">
                                                        <span className="arrow-right" style={{top:14+"px"}}></span>
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                        <p className="fs-7 mt-2 mb-1 fw-bold text-center">{item.purchaseRenewals}</p>
                                                        <span className="badge bg-info align-items-center justify-content-center fs-7" style={{display:"inline-flex", minWidth:100+"px", height:38+"px"}}>Renewals</span>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
            </div>
        </div>
    )
}

export default Properties