import React, { useContext } from "react";

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import APICalls from "../api/APICalls.js";
import { AppContext } from "../context/AppProvider.js";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown'

import { ROLES } from "../App.js";

const Settings = (props) => {
    const {apps, app, setApps, setApp } = useContext(AppContext)
    const { userData, setUserData } = useContext(AppContext)
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [tab, setTab] = useState('members');

    const apiCalls = new APICalls(axiosPrivate, navigate, location)

    /* Invite user modal */
    
    const [showUserEdit, setUserEditShow] = useState(false);
    const [userEditEmail, setUserEditEmail] = useState(null);
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState(0);
    const [userEditError, setUserEditError] = useState(null);
    const [userDeleteError, setUserDeleteError] = useState(null);

    const modalUserEditClose = () => {
        setUserDeleteError(null)
        setUserEditShow(false);
        setUserEditEmail(null);
        setUserEmail("")
        setUserName("")
        setUserRole(0)
        setUserEditError(null)
    }

    const modalUserEditShow = () => {
        setUserDeleteError(null)
        setUserEditShow(true);
    }

    function handleUserEditSaveChanges() {
        if (userEditEmail == null) {
            apiCalls.users().invite(userEmail, userName, userRole, (err, data) => {
                if (err) {
                    setUserEditError(err)
                } else {
                    modalUserEditClose();
                    dataFetch();
                }
            })
        } else {
            apiCalls.users().update(userEditEmail, userName, userRole, userEmail, (err, data) => {
                if (err) {
                    setUserEditError(err)
                } else {
                    modalUserEditClose();
                    dataFetch();

                    if (userData.email == userEditEmail) {
                        let newUserData = {...userData}
                        newUserData.email = data.email
                        newUserData.name = data.name
                        setUserData(newUserData);
                    }
                }
            })
        }
    }

    function handleUserEdit(user) {
        setUserDeleteError(null)
        setUserEditEmail(user.email)
        setUserEmail(user.email)
        setUserName(user.name)
        setUserRole(user.role)
        setUserEditShow(true)
    }

    /* Confirm delete member modal */

    const [showDeleteConfirm, setDeleteConfirmShow] = useState(false);
    const handleDeleteConfirmClose = () => setDeleteConfirmShow(false);
    const handleDeleteConfirmShow = () => setDeleteConfirmShow(true);

    function handleUserDelete(user) {
        setUserEditEmail(user.email);
        setDeleteConfirmShow(true)
    }

    function handleDeleteConfirmNo() {
        setDeleteConfirmShow(false)
        setUserEditEmail(null);
    }

    function handleDeleteConfirmYes() {
        setUserDeleteError(null)
        apiCalls.users().delete(userEditEmail, (err, data) => {
            if (err) {
                console.log(err)
                setUserDeleteError(err)
                setUserEditEmail(null);
            } else {
                dataFetch();
                setUserEditEmail(null);
            }
            setDeleteConfirmShow(false)
        })
    }

     /* Add modal */
    
    const [showAdd, setEditShow] = useState(false);
    const [editId, setEditId] = useState(-1);
    const [action, setAction] = useState(-1);
    const [name, setName] = useState("");
    const [errorAdd, setErrorEdit] = useState(null);

    const modalEditClose = () => {
        setEditId(-1)
        setName("")
        setEditShow(false);
    }

    const modalEditShow = () => {
        setErrorEdit(null)
        setEditShow(true);
    }

    function handleSaveChanges() {
        apiCalls.apps().update(editId, name, (err, data) => {
            if (err) {
                setErrorEdit(err)
            } else {
                setEditShow(false);
                apiCalls.apps().get((err, appsData) => {
                    if (err) {
                    } else {
                        setApps(appsData);
                        const found = appsData.find(obj => {
                            return obj.bundleId === app.bundleId;
                        });
                        setApp(found);
                    }
                });
                dataFetch();
            }
        });
    }

    /* Confirm modal */

    const [showConfirm, setConfirmShow] = useState(false);
    const handleConfirmClose = () => setConfirmShow(false);
    const handleConfirmShow = () => setConfirmShow(true);

    function handleConfirmNo() {
        setConfirmShow(false)
    }

    function handleConfirmYes() {
        if (action == 0) { //Deactivate
            apiCalls.apps().deactivate(editId, (err, data) => {
                if (err) {
                    console.log("Error: " + err);
                } else {
                    setConfirmShow(false);
                    dataFetch();
                }
            });
        } else { //Activate
            apiCalls.apps().activate(editId, (err, data) => {
                if (err) {
                    console.log("Error: " + err);
                } else {
                    setConfirmShow(false);
                    dataFetch();
                }
            });
        }
    }

    /* Data */

    const [data, setData] = useState();
    const [users, setUsers] = useState();

    const dataFetch = async () => {
        apiCalls.apps().getAvailable((err, data) => {
            setData(data)
        })

        apiCalls.users().all((err, data) => {
            console.log(data);
            setUsers(data?.users)
        })
    };
    
    useEffect(() => {
        dataFetch();
    }, []);

    const handleActivate = (item) => {
        setEditId(item.bundleId);
        setAction(1);
        setConfirmShow(true)
    }

    const handleDeactivate = (item) => {
        setEditId(item.bundleId);
        setAction(0);
        setConfirmShow(true)
    }

    const handleEdit = (item) => {
        setEditId(item.bundleId);
        setName(item.customName);
        setEditShow(true)
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <h4>Settings</h4>

                <Tabs defaultActiveKey="members" id="justify-tab-example" className="tab-content mb-3 mt-5" >
                    <Tab eventKey="members" title="Members">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="fs-7 fw-bold">Email</th>
                                    <th scope="col" className="fs-7 fw-bold">Name</th>  
                                    <th scope="col" className="fs-7 fw-bold">Role</th>
                                    <th scope="col" className="fs-7 fw-bold">Status</th>
                                    <th scope="col" className="fs-7 fw-bold">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.map(item => 
                                <tr key={item?.email}> 
                                <td className="fs-7 align-middle">{item?.email}</td>  
                                    <td className="fs-7 align-middle">{item?.name}</td>  
                                    {(item.role == ROLES.Admin) ? (
                                        <td className="fs-7 align-middle">Admin</td>    
                                    ) : (
                                        <td className="fs-7 align-middle">Viewer</td>    
                                    )} 
                                        
                                    <td className="fs-7 align-middle">
                                        {(item?.status === 1) ? (
                                            <>
                                                <button type="button" className="btn btn-success me-2 fs-7">Joined</button>
                                            </>
                                        ) : (
                                            <>
                                                <button type="button" className="btn btn-warning me-2 fs-7">Pending</button>
                                            </>
                                        )}
                                    </td>    
                                    <td className="fs-7 align-middle">
                                        {(userData.role == ROLES.Admin) ? (
                                            <button type="button" className="btn btn-warning me-2 fs-7" onClick={() => handleUserEdit(item)}><PencilFill color="white" /></button>
                                        ) : (
                                            <>
                                            {(userData?.email == item.email) ? (
                                                <button type="button" className="btn btn-warning me-2 fs-7" onClick={() => handleUserEdit(item)}><PencilFill color="white" /></button>
                                            ) :
                                            (
                                                <></>
                                            )}
                                            </>
                                        )}  
                                        {(userData.role == ROLES.Admin) ? (
                                            <>
                                            {(userData?.email == item.email) ? (
                                                <></>
                                            ) :
                                            (
                                                <button type="button" className="btn btn-danger fs-7" onClick={() => handleUserDelete(item)}><TrashFill color="white" /></button>
                                            )}
                                            </>
                                            
                                        ) : (
                                            <></>
                                        )}  
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        {userDeleteError == null ? "" : <Form.Label className="text-danger">{userDeleteError}</Form.Label>}
                        {(userData.role == ROLES.Admin) ? (
                            <>
                                <button type="button" className="btn btn-primary mt-3 fs-7" onClick={modalUserEditShow}>Invite New Member</button>
                            </>
                        ) : (
                            <></>
                        )}   
                    </Tab>
                    <Tab eventKey="apps" title="Apps" className="tab-pane">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="fs-7 fw-bold text-center">Icon</th>
                                    <th scope="col" className="fs-7 fw-bold">Name</th>
                                    <th scope="col" className="fs-7 fw-bold">Custom Name</th>
                                    {(userData.role == ROLES.Admin) ? (
                                        <th scope="col" className="fs-7 fw-bold">Actions</th>
                                    ) : (
                                        <></>
                                    )}   
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(item => 
                                <tr key={item?.name}> 
                                    <td className="fs-7 text-center align-middle" style={{width:5 + "%"}}><Image src={"data:image/png;base64,"+item?.icon} width="35" height="35" className="rounded-20pc"/></td>
                                    <td className="fs-7 align-middle">{item?.name}</td>
                                    <td className="fs-7 align-middle">{item?.customName}</td>     
                                    {(userData.role == ROLES.Admin) ? (
                                        <>
                                            <td className="fs-7 align-middle">
                                                {(item?.active === 1) ? (
                                                    <>
                                                        <button type="button" className="btn btn-danger me-2 fs-7" onClick={() => handleDeactivate(item)}>Deactivate</button>
                                                        <button type="button" className="btn btn-warning fs-7" onClick={() => handleEdit(item)}><PencilFill color="white" /></button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button type="button" className="btn btn-success fs-7" onClick={() => handleActivate(item)}>Activate</button>
                                                    </>
                                                )}
                                            </td>  
                                        </>
                                    ) : (
                                        <></>
                                    )}   
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </Tab>
                </Tabs>

                <Modal show={showUserEdit} onHide={modalUserEditClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><h5 className="mb-0">{userEditEmail == null ? "Invite New Member" : "Edit Member"}</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                        {userEditError == null ? "" : <Form.Label className="text-danger">{userEditError}</Form.Label>}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-7">Name</Form.Label>
                        <Form.Control
                            className="fs-7"
                            type="text"
                            placeholder="Enter name"
                            autoFocus
                            value={userName} 
                            onChange={(e) => setUserName(e.target.value)}
                            required="true"
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-7">Email</Form.Label>
                        <Form.Control
                            className="fs-7"
                            type="text"
                            placeholder="Enter email"
                            value={userEmail} 
                            onChange={(e) => setUserEmail(e.target.value)}
                            required="true"
                            disabled={userEditEmail == null || userData.email == userEditEmail ? false : true}
                        />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-7">Role</Form.Label>
                        <Dropdown className="condition_dropdown" >
                            <Dropdown.Toggle disabled={userData.role == ROLES.Admin && userData.email != userEditEmail ? false : true} variant="outline-secondary" className="fs-7">{userRole == 1 ? "Admin" : "Viewer"}</Dropdown.Toggle>
                            <Dropdown.Menu className="menu">
                                 <Dropdown.Item eventKey="0" key="0" title="Viewer" className="fs-7" onClick={(e) => setUserRole(0)}>Viewer</Dropdown.Item>
                                <Dropdown.Item eventKey="1" key="1" title="Admin" className="fs-7" onClick={(e) => setUserRole(1)}>Admin</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> 
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="fs-7" onClick={modalUserEditClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" className="fs-7" onClick={handleUserEditSaveChanges}>
                        {userEditEmail == null ? "Invite" : "Save changes"}
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteConfirm} onHide={handleDeleteConfirmClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><h5 className="mb-0">Delete Member</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="fs-7">
                        Are you sure you want to delete this member?
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="fs-7" onClick={handleDeleteConfirmNo}>
                        No, Gracias
                    </Button>
                    <Button variant="primary" className="fs-7" onClick={handleDeleteConfirmYes}>
                        Si Señor 🍕
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAdd} onHide={modalEditClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><h5 className="mb-0">Set custom name</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                        {errorAdd == null ? "" : <Form.Label className="text-danger">{errorAdd}</Form.Label>}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-7">Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            autoFocus
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            required="true"
                            className="fs-7"
                        />
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="fs-7"  onClick={modalEditClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" className="fs-7"  onClick={handleSaveChanges}>
                        Save changes
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfirm} onHide={handleConfirmClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><h5 className="mb-0">Deactivate</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="fs-7">
                        Are you sure you want to deactivate this app?
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="fs-7" onClick={handleConfirmNo}>
                        No, Gracias
                    </Button>
                    <Button variant="primary" className="fs-7" onClick={handleConfirmYes}>
                        Si Señor 🍕
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default Settings