import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import moment from 'moment';
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

import APICalls from "../api/APICalls.js";

import { AppContext } from "../context/AppProvider.js";
import Table from "react-bootstrap/Table";

import { CaretDownFill, ArrowLeft } from "react-bootstrap-icons";

const UserDetails = (props) => {
    const {app} = useContext(AppContext)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const apiCalls = new APICalls(axiosPrivate, navigate, location);

    const queryParameters = new URLSearchParams(window.location.search)
    const parameterID = queryParameters.get("id")
    const parameterEmail = queryParameters.get("email")

    /* Error */
    const [errorDesc, setErrorDesc] = useState(null);

    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    /* Data */
    
    const [user, setUser] = useState();
    const [devices, setDevices] = useState();
    const [transactions, setTransactions] = useState();
    const [transactionEvents, setTransactionEvents] = useState();

    // fetch data
    const dataFetch = async () => {
        apiCalls.reports().getUserInfo(parameterEmail, parameterID, app.bundleId, (err, data) => {
            if (err) {
                setErrorDesc(err);
            } else {
                setUser(data.user);
                setDevices(data.devices);

                let sortedTransactions = data.transactions.sort((a, b) => moment(b.purchaseDate) - moment(a.purchaseDate));
                setTransactions(sortedTransactions);

                let mergedTransactions = merge(data.transactionEvents, data.transactions)
                console.log(mergedTransactions)
                setTransactionEvents(mergedTransactions);
            }
        });
    };

    const merge = (arr1, arr2) => {
        const temp = []
      
        arr1.forEach(x => {
            const found = arr2.find(obj => {
                return obj.webOrderLineItemId === x.webOrderLineItemId;
            })
          temp.push({ ...x, ...{productId: found.productId, purchaseDate: found.purchaseDate, expirationDate: found.expirationDate, estProceeds: found.estProceeds } })
        })
      
        return temp
      }

      const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            navigate("/users", {state: {page: location?.state?.page}, replace: false})
          /*if (window.confirm("Do you want to go to Test Listing")) {
            setBackbuttonPress(true)
            //props.history.go(listingpage)
          } else {
            window.history.pushState(null, null, window.location.pathname);
            setBackbuttonPress(false)
          }*/
        }
      }

    useEffect(() => {
        dataFetch();

        window.history.pushState(location.state, null, null);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);
      
    function onClickHandler(e, device) {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
        //checkCollapse(device);
    };

    /*function checkCollapse(device) {
        var element = document.getElementById(device.idfv);
        if (element?.className?.indexOf("collapse show") > -1) {
            return true
        } 
        return false
    }*/

    const countTransactionsEstProceeds = () => {  
        var count = 0
        transactions?.map(transaction => 
            count += transaction.estProceeds
        )

        return count.toFixed(2);
    }

    const renderTransactionsStatus = (transaction) => {            
        if (transaction?.isTrialPeriod) {
            return <td style={{width: 2 + "%"}} className="bg-primary"></td>
        } else {
            const expirationDate = moment(transaction?.expirationDate);
            const today = moment();
            if (today.isAfter(expirationDate)) {
                return <td style={{width: 2 + "%"}} className="bg-warning"></td>
            } else {
                return <td style={{width: 2 + "%"}} className="bg-success"></td>
            }
        }
    }

    const renderTransactionEventStatus = (event) => {
        if (event?.notificationType == "SUBSCRIBED" || event?.notificationType == "DID_RENEW") {
            return <td className="bg-success text-white text-center align-middle px-3"  rowSpan={2}></td>
        } else if (event?.notificationType == "EXPIRED") {
            return <td className="bg-danger text-white text-center align-middle"  rowSpan={2}></td>
        } else {
            return <td className="bg-warning text-white text-center align-middle"  rowSpan={2}></td>
        }
    }

    return (
        <div className="content" >
            <div className="container-fluid">
                <ArrowLeft color="black" width="32" height="32" onClick={() => { navigate("/users", {state: {page: location?.state?.page}, replace: false}) }} />
                {
                    user != undefined
                    ? 
                    <>
                {errorDesc == null ? "" : <p className="text-danger mt-3 mt-xl-0 mb-0">Error: {errorDesc}</p>}
                {/* USER DETAILS */}
                <div className="row">
                    <div className="col-12 col-xl-6 mt-3">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-header-title mb-0">User Details</h5>
                            </div> 
                            <div className="card-body fs-7">
                                <table id="w1" className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td  scope="col" className="text-start">UUID</td>
                                            <td className="text-end"><small className="text-muted">{user?.id != null ? user?.id : "(not set)"}</small></td>
                                        </tr>
                                        <tr>
                                            <td  scope="col" className="text-start">Email</td>
                                            <td className="text-end"><small className="text-muted">{user?.email != null ? user?.email : "(not set)"}</small></td>
                                        </tr>
                                        <tr>
                                            <td  scope="col" className="text-start">Has receipt</td>
                                            <td className="text-end"><small className="text-muted">{user?.hasReceipt == 1 ? <span className="badge text-bg-success">Yes</span> : <span className="badge text-bg-danger">No</span>}</small></td>
                                        </tr>
                                        <tr>
                                            <td  scope="col" className="text-start">Install date</td>
                                            <td className="text-end"><small className="text-muted">{moment(user?.installDate).format("DD.MM.YYYY HH:mm:ss")}</small></td>
                                        </tr>
                                        <tr>
                                            <td  scope="col" className="text-start">Created date</td>
                                            <td className="text-end"><small className="text-muted">{moment(user?.created).format("DD.MM.YYYY HH:mm:ss")}</small></td>                     
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* DEVICES */}
                    <div className="col-12 col-xl-6 mt-3">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-header-title mb-0">Devices</h5>
                            </div> 
                            <div className="card-body fs-7">
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col">Device</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">FB Token</th>
                                            <th scope="col">IDFV</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {devices?.map(device => 
                                        <>
                                            <tr role="button" onClick={(e) => onClickHandler(e, device)}>
                                                <td style={{width: 30 + "%"}}>{device?.deviceModel}</td>
                                                <td style={{width: 40 + "%"}}>{moment(device?.created).format("DD.MM.YYYY HH:mm:ss")}</td>
                                                <td style={{width: 14 + "%"}}>{device?.firebaseToken != null ? <span className="badge text-bg-success">Yes</span> : <span className="badge text-bg-danger">No</span>}</td>
                                                <td style={{width: 14 + "%"}}>{device?.idfv != null ? <span className="badge text-bg-success">Yes</span> : <span className="badge text-bg-danger">No</span>}</td>
                                                <td style={{width: 2 + "%"}}><CaretDownFill color="black" /></td>
                                            </tr>
                                            <tr id={device.idfv} className="collapse">
                                                <td colSpan="4" className="p-0">
                                                    <Table striped hover className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-start">Key</th>
                                                            <th scope="col" className="text-end">Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {device?.properties?.map(property => 
                                                        <tr>
                                                            <td  scope="col" className="text-start">
                                                                {property?.p_key}
                                                            </td>
                                                            <td className="text-end">
                                                                <small className="text-muted">{property?.p_value}</small>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                    </Table>  
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                    {/* TRANSACTIONS */}
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-6"><h5 className="card-header-title float-start mb-0">Transactions </h5></div>  
                                    <div className="col-6"><h6 className="card-header-title float-end mb-0 me-3">Total: {countTransactionsEstProceeds()} €</h6></div>  
                                </div>
                            </div> 
                            <div className="card-body mb- fs-7">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col">Status</th>
                                            <th scope="col">Product ID</th>
                                            <th scope="col">Purchased</th>
                                            <th scope="col">Expires</th>
                                            <th scope="col" className="text-center">Est. proceeds</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {transactions?.map(transaction => 
                                        <>
                                            <tr>
                                                {renderTransactionsStatus(transaction)}
                                                <td style={{width: 26 + "%"}}>{transaction?.productId}</td>
                                                <td style={{width: 24 + "%"}}>{moment(transaction?.purchaseDate).format("DD.MM.YYYY HH:mm:ss")}</td>
                                                <td style={{width: 24 + "%"}}>{moment(transaction?.expirationDate).format("DD.MM.YYYY HH:mm:ss")}</td>
                                                <td style={{width: 24 + "%"}} className="fw-bold text-center">{transaction?.estProceeds} €</td>
                                            </tr>
                                        </>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                    {/* TRANSACTIONS EVENT */}
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-header-title mb-0">Transaction events</h5>
                            </div> 
                            <div className="card-body fs-7">
                                <Table hover className="table1">
                                    <thead>
                                        <tr>
                                            <th scope="col" rowSpan={2} className="text-center align-middle">Status</th>
                                            <th scope="col">Product ID</th>
                                            <th scope="col">Notification type</th>
                                            <th scope="col">Sub type</th>
                                            <th scope="col" className="text-center" colSpan={2}>Est. proceeds</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {transactionEvents?.map(event => 
                                        <>
                                            <tr>
                                                {renderTransactionEventStatus(event)}
                                                <td style={{width: 25 + "%"}}>{event?.productId}</td>
                                                <td style={{width: 25 + "%"}}>{event?.notificationType}</td>
                                                <td style={{width: 25 + "%"}}>{event?.subtype}</td>
                                                <td style={{width: 25 + "%"}} className="fw-bold text-center align-middle" rowSpan={2}>
                                                    {
                                                        (event?.notificationType == "SUBSCRIBED" || event?.notificationType == "DID_RENEW") ?  event?.estProceeds + " €" : ""
                                                    }
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: 25 + "%"}}>
                                                    <small className="text-muted fw-bold">Created</small>
                                                    <br></br>
                                                    {moment(event?.created).format("DD.MM.YYYY HH:mm:ss")}
                                                    </td>
                                                <td style={{width: 25 + "%"}}>
                                                    <small className="text-muted fw-bold">Purchased</small>
                                                    <br></br>
                                                    {moment(event?.purchaseDate).format("DD.MM.YYYY HH:mm:ss")}
                                                    </td>
                                                <td style={{width: 25 + "%"}}>
                                                    <small className="text-muted fw-bold">Expires</small>
                                                    <br></br>
                                                    {moment(event?.expirationDate).format("DD.MM.YYYY HH:mm:ss")}
                                                    </td>
                                            </tr>
                                        </>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                : <>
                    <div className="missing__container">        
                        <img src={require("../resources/images/unauthorized.png")} alt="404" title="404" width="500" height="500" className="missing__image"></img>
                        <article className="missing__text-container">
                            <h1 style={{paddingBottom: "1rem"}}>User not found</h1>
                            <p>Click back to go on previous page.</p>
                        </article>
                    </div>
                </>
            }
            </div>
        </div>
    )
}

export default UserDetails